import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, Button, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AuthorInformationFields from '../components/AuthorInformationFields';
import { AuthorManager, PinManager } from '../models';
import RecordingStore from '../stores/RecordingStore';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { IPin } from '../types';
import { constructUrl } from '../utilities/constructUrl';
import './AuthorDashboard.css';
import { IProject } from '../types/Project';
import { ProjectManager } from '../models';
import AudioPlayer from '../components/AudioPlayer';
import { handleFileUpload } from '../utilities/handleFileUpload';
import { Icon } from '../components/Icon';
import { updateTags } from '../utilities/tagUpdate'; // Import the updateTags function
import { useNavigate } from 'react-router-dom';
import authenticationStore from '../stores/AuthenticationStore';

// Define a new interface for author details that includes the imageFile property
interface AuthorDetails {
  authorKey?: string;  // Make authorKey optional
  authorName: string;
  authorBio: string;
  authorWebsite: string;
  authorTags: string[];
  authorImageUrl: string;
  imageFile?: File | null;
}

const AuthorDashboard: React.FC = observer(() => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authorDetails, setAuthorDetails] = useState<AuthorDetails>({
    authorKey: '',
    authorName: '',
    authorBio: '',
    authorWebsite: '',
    authorTags: [],
    authorImageUrl: '/assets/icons/user.svg',
  });
  const [authorPins, setAuthorPins] = useState<IPin[]>([]);
  const [projectsMap, setProjectsMap] = useState<Map<string, IProject>>(new Map());
  const mapRef = useRef<L.Map | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [editingDetails, setEditingDetails] = useState<AuthorDetails | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      
      setIsLoading(true);
      

      if (!authenticationStore.isAuthenticated) {
        
        navigate('/author-login');
        return;
      }

      
      const user = authenticationStore.user;
      
      if (user) {
        
        try {
          const authorData = await AuthorManager.getAuthorByUserKey(user.uid);
          
          if (authorData) {
            // Set author details here
            
            setAuthorDetails({
              authorKey: authorData.authorKey || '',
              authorName: authorData.name || '',
              authorBio: authorData.bio || '',
              authorWebsite: authorData.website || '',
              authorTags: authorData.tags.authorTags || [],
              authorImageUrl: authorData.image || '/assets/icons/user.svg',
            });
            
    
            if (authorData.authorKey) {
              await RecordingStore.fetchRecordingsForAuthor(authorData.authorKey);
              const pins = await fetchPinsForAuthor(authorData.authorKey);
              setAuthorPins(pins);

              // Fetch projects for all recordings
              const projectKeys = Array.from(new Set(Array.from(RecordingStore.recordings.values()).map(r => r.project).filter((key): key is string => key !== undefined)));
              const projects = await Promise.all(projectKeys.map(key => ProjectManager.getProject(key)));
              const projectMap = new Map(projects.filter((p): p is IProject => p !== null).map(p => [p.project_id, p]));
              setProjectsMap(projectMap);
            }
          } else {
            
          }
        } catch (error) {
          console.error('9. Error fetching author data:', error);
        }
      } else {
        
      }

      
      setIsLoading(false);
    };

    checkAuthAndFetchData();
  }, [navigate]);

  useEffect(() => {
    if (isLoading) return; // Don't initialize map while loading

    if (!mapRef.current && document.getElementById('authorMap')) {
      initializeMap();
    } else if (mapRef.current) {
      updateMapPins();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [authorPins, isLoading]);

  const fetchPinsForAuthor = async (authorKey: string): Promise<IPin[]> => {
    const recordings = Array.from(RecordingStore.recordings.values());
    const pinKeys = recordings.map(recording => recording.pinKey).filter((key): key is string => key !== undefined);
    const pins = await Promise.all(pinKeys.map(pinKey => PinManager.getPin(pinKey)));
    return pins.filter((pin): pin is IPin => pin !== null);
  };

  const initializeMap = () => {
    if (!mapRef.current) {
      mapRef.current = L.map('authorMap').setView([0, 0], 2);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(mapRef.current);

      authorPins.forEach(pin => {
        if (pin.location?.geoPoint) {
          const { lat, long } = pin.location.geoPoint;
          const iconUrl = constructUrl(pin.pinIcon);
          const icon = L.icon({
            iconUrl: iconUrl,
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          });
          L.marker([Number(lat), Number(long)], { icon }).addTo(mapRef.current!);
        }
      });

      if (authorPins.length > 0) {
        const validPins = authorPins.filter(pin => pin.location?.geoPoint);
        if (validPins.length > 0) {
          const bounds = L.latLngBounds(validPins.map(pin => [Number(pin.location.geoPoint!.lat), Number(pin.location.geoPoint!.long)]));
          mapRef.current.fitBounds(bounds);
        }
      }
    }
  };

  const updateMapPins = () => {
    if (!mapRef.current) return;

    // Clear existing markers
    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        mapRef.current!.removeLayer(layer);
      }
    });

    // Add new markers
    const validPins = authorPins.filter(pin => pin.location?.geoPoint);
    validPins.forEach(pin => {
      const { lat, long } = pin.location.geoPoint!;
      const iconUrl = constructUrl(pin.pinIcon);
      const icon = L.icon({
        iconUrl: iconUrl,
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      });
      L.marker([Number(lat), Number(long)], { icon }).addTo(mapRef.current!);
    });

    // Fit bounds if there are valid pins
    if (validPins.length > 0) {
      const bounds = L.latLngBounds(validPins.map(pin => [Number(pin.location.geoPoint!.lat), Number(pin.location.geoPoint!.long)]));
      mapRef.current.fitBounds(bounds);
    } else {
      // If no pins, set a default view
      mapRef.current.setView([0, 0], 2);
    }
  };

  const toggleEdit = () => {
    if (isEditing) {
      setEditingDetails(null);
    } else {
      // Ensure all required fields are included when setting editingDetails
      setEditingDetails({
        authorKey: authorDetails.authorKey,
        authorName: authorDetails.authorName,
        authorBio: authorDetails.authorBio,
        authorWebsite: authorDetails.authorWebsite,
        authorTags: authorDetails.authorTags,
        authorImageUrl: authorDetails.authorImageUrl,
        imageFile: null  // or authorDetails.imageFile if you want to keep the current file
      });
    }
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    if (editingDetails && authorDetails.authorKey) {
      try {
        setIsUploading(true);
        let imageUrl = editingDetails.authorImageUrl;

        // If there's a new image file, upload it
        if (editingDetails.imageFile) {
          const filePath = `author_images/${authorDetails.authorKey}/`;
          const fileName = `profile_${Date.now()}.jpg`;
          imageUrl = await handleFileUpload(
            editingDetails.imageFile,
            (url) => {
              setEditingDetails((prev) => {
                if (prev) {
                  return {
                    ...prev,
                    authorImageUrl: typeof url === 'function' ? url(prev.authorImageUrl) : url
                  };
                }
                return null;
              });
            },
            filePath,
            fileName
          );
        }

        // Update author details including the new image URL
        await AuthorManager.updateAuthor(authorDetails.authorKey, {
          name: editingDetails.authorName,
          bio: editingDetails.authorBio,
          website: editingDetails.authorWebsite,
          tags: { authorTags: editingDetails.authorTags || [] },
          image: imageUrl,
        });

        setAuthorDetails({
          ...editingDetails,
          authorImageUrl: imageUrl,
        });
        setIsEditing(false);
        setEditingDetails(null);
      } catch (error) {
        console.error('Error saving author details:', error);
        // Optionally, you can add error handling UI here
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditingDetails(null);
  };

  const getAuthorImageUrl = (imageUrl: string) => {
    if (!imageUrl || imageUrl === '/assets/icons/user.svg') {
      return '/assets/icons/user.svg';
    }
    // Check if the URL is already a full Firebase Storage URL
    if (imageUrl.startsWith('https://firebasestorage.googleapis.com')) {
      return imageUrl; // Return as is if it's already a full URL
    }
    return constructUrl(imageUrl);
  };

  const handleUpdateTags = useCallback(async (authorKey: string, newTags: string[]) => {
    try {
      // First, update the author's tags in the database
      await AuthorManager.updateAuthor(authorKey, {
        tags: { authorTags: newTags }
      });

      // Then, use the updateTags utility to update tags in other collections
      await updateTags({
        authorKey,
        pinKeys: authorPins.map(pin => pin.pinKey).filter((key): key is string => key !== undefined),
        authorTags: newTags,
        initialAuthorTags: authorDetails.authorTags
      });

    } catch (error) {
      console.error('Error updating author tags:', error);
      // Optionally, you can add error handling UI here
    }
  }, []);

  

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="author-dashboard">
      <Box display="flex" justifyContent="center" mb={2}>
        <Icon icon="overhearlight" width={120} height={140} /> {/* Adjust these dimensions as needed */}
      </Box>
      <Typography variant="h4" gutterBottom>
        Author Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} className={`dashboard-section author-profile ${isEditing ? 'editing-mode' : ''}`}>
            <Typography variant="h6" className="section-title">Author Profile</Typography>
            {isEditing && editingDetails ? (
              <Box className="edit-mode-container">
                <AuthorInformationFields
                  initialAuthorName={editingDetails.authorName}
                  initialAuthorBio={editingDetails.authorBio}
                  initialAuthorWebsite={editingDetails.authorWebsite}
                  initialAuthorTags={editingDetails.authorTags}
                  initialAuthorImageUrl={editingDetails.authorImageUrl}
                  authorKey={authorDetails.authorKey || ''}
                  pinKeys={authorPins.map(pin => pin.pinKey).filter((key): key is string => key !== undefined)} // Pass all pinKeys
                  onAuthorDetailsChange={(details) => setEditingDetails(prev => ({ ...prev!, ...details }))}
                  setAuthorTags={(newTags) => setEditingDetails(prev => ({
                    ...prev!,
                    authorTags: typeof newTags === 'function' ? newTags(prev!.authorTags) : newTags
                  }))}
                />
                <Box className="edit-buttons-container">
                  <Button onClick={handleCancel} variant="outlined" color="secondary" disabled={isUploading}>
                    Cancel
                  </Button>
                  <Button onClick={handleSave} variant="contained" color="primary" disabled={isUploading}>
                    {isUploading ? 'Saving...' : 'Save'}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box className="author-profile-content">
                <img 
                  src={getAuthorImageUrl(authorDetails.authorImageUrl)}
                  alt={authorDetails.authorName} 
                  className="author-image" 
                  onError={(e) => {
                    console.error("Error loading image:", e);
                    
                    (e.target as HTMLImageElement).src = '/assets/icons/user.svg';
                  }}
                />
                <Typography variant="h6" className="author-name">{authorDetails.authorName}</Typography>
                <Typography variant="body1" className="author-bio">{authorDetails.authorBio}</Typography>
                <Typography variant="body2" className="author-website">
                  Website: <a href={authorDetails.authorWebsite} target="_blank" rel="noopener noreferrer">{authorDetails.authorWebsite}</a>
                </Typography>
                <Box className="author-tags">
                  {authorDetails.authorTags.map((tag, index) => (
                    <span key={index} className="author-tag">{tag}</span>
                  ))}
                </Box>
                <Button onClick={toggleEdit} variant="contained" color="primary" className="edit-button">
                  Edit Profile
                </Button>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} className="dashboard-section">
            <Typography variant="h6">My Recordings</Typography>
            {RecordingStore.recordings.size > 0 ? (
              <List className="recordings-list">
                {Array.from(RecordingStore.recordings.values()).map((recording) => (
                  <ListItem key={recording.key} className="recording-item" disableGutters>
                    <Box className="recording-item-inner">
                      <Box className="recording-item-content">
                        <Typography variant="subtitle1" className="recording-title">
                          {recording.file?.title || 'Untitled Recording'}
                        </Typography>
                        <Box className="audio-player-container">
                          <AudioPlayer audioUrl={RecordingStore.getAudioUrl(recording.key) || ''} />
                        </Box>
                      </Box>
                      <Box className="recording-details">
                        <div className="recording-detail-item">
                          <span className="recording-detail-label">Project:</span>
                          {recording.project && projectsMap.has(recording.project) ? projectsMap.get(recording.project)?.projectName : 'Unknown'}
                        </div>
                        <div className="recording-detail-item">
                          <span className="recording-detail-label">Description:</span>
                          {recording.file?.description || 'No description available'}
                        </div>
                        <div className="recording-detail-item">
                          <span className="recording-detail-label">Collection Count:</span>
                          {recording.collectionLog?.timesCollected || 0}
                        </div>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No recordings found.</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className="dashboard-section map-container">
            <Typography variant="h6">My Pins</Typography>
            {!isLoading && <Box id="authorMap" style={{ height: '400px', width: '100%' }}></Box>}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});

export default AuthorDashboard;
