import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import "./MapView.css";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useNavigate } from "react-router-dom";
import AllDataManager from '../models/AllDataManager';
import { PinManager } from "../models";
import { IPinWithRecordings, IPin, GeoPointModel } from "../types";
import { constructUrl } from "../utilities/constructUrl";
import { Icon, ButtonRow, AdminNav, PortalPopup, PinEditor } from "../components";
import { useAdminNav } from "../hooks/useAdminNav";
import { observer } from 'mobx-react-lite';
import pinStore from '../stores/PinStore';
import { LatLng } from 'leaflet';

export const MapView: FunctionComponent = observer(() => {
  const navigate = useNavigate();
  const [showCreatePin, setShowCreatePin] = useState(false);
  const [editingPin, setEditingPin] = useState<IPin | null>(null); // New state for editing pin
  const [newPinCoords, setNewPinCoords] = useState({ lat: 0, long: 0 });
  const mapRef = useRef<L.Map | null>(null);
  const markersRef = useRef<Map<string, L.Marker>>(new Map());
  const [pinsWithRecordings, setPinsWithRecordings] = useState<IPinWithRecordings[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tempMarker, setTempMarker] = useState<L.Marker | null>(null);
  const [iconUrl, setIconUrl] = useState<string>('/default-pin-icon@2x.png');
  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole
  } = useAdminNav();

  // Replace the existing lastDraggedPin state with this
  const [pinMoveHistory, setPinMoveHistory] = useState<Array<{ pin: IPin, prevPosition: L.LatLng }>>([]);

  const selectedProjectData = projects.find(project => project.project_id === selectedProject);
  const centerMapOnLocation = async (location: string) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${location}`);
      const data = await response.json();
      if (data && data.length > 0) {
        const { lat, lon } = data[0];
        if (mapRef.current) {
          mapRef.current.panTo(new LatLng(lat, lon));
        }
      } else {
        console.error('Location not found');
      }
    } catch (error) {
      console.error('Error during geocoding:', error);
    }
  };

  const closeCreatePin = useCallback(() => {
    if (tempMarker) {
      tempMarker.remove(); // Remove the temporary marker from the map
      setTempMarker(null); // Reset the temporary marker state
    }
    setShowCreatePin(false);
  }, [tempMarker]);

  const closeEditPin = useCallback(() => {
    setEditingPin(null);
  }, []);

  const handleUpdatePin = async (updatedPin: IPin) => {
    try {
      await pinStore.updatePin(updatedPin);
      setEditingPin(null);
      // Update the marker on the map
      if (mapRef.current && updatedPin.location?.geoPoint) {
        const marker = markersRef.current.get(updatedPin.pinKey);
        if (marker) {
          const lat = parseFloat(updatedPin.location.geoPoint.lat.toString());
          const long = parseFloat(updatedPin.location.geoPoint.long.toString());
          marker.setLatLng([lat, long]);
          const iconUrl = constructUrl(updatedPin.pinIcon);
          const icon = L.icon({
            iconUrl: iconUrl,
            iconSize: [70, 70],
            iconAnchor: [35, 70]
          });
          marker.setIcon(icon);
        }
      }
    } catch (error) {
      console.error('Error updating pin:', error);
    }
  };

  const handleSavePin = async (newPin: IPin) => {
    try {
      await pinStore.createPin(newPin);
      setShowCreatePin(false);
      // Optionally, you can add the new pin to the map here
      if (mapRef.current && newPin.location?.geoPoint) {
        const lat = typeof newPin.location.geoPoint.lat === 'string' 
          ? parseFloat(newPin.location.geoPoint.lat) 
          : newPin.location.geoPoint.lat;
        const long = typeof newPin.location.geoPoint.long === 'string' 
          ? parseFloat(newPin.location.geoPoint.long) 
          : newPin.location.geoPoint.long;

        if (!isNaN(lat) && !isNaN(long)) {
          const iconUrl = constructUrl(newPin.pinIcon);
          const icon = L.icon({
            iconUrl: iconUrl,
            iconSize: [70, 70],
            iconAnchor: [35, 70]
          });
          const marker = L.marker([lat, long], { icon: icon }).addTo(mapRef.current);
          markersRef.current.set(newPin.pinKey, marker);
        } else {
          console.error('Invalid latitude or longitude');
        }
      }
    } catch (error) {
      console.error('Error creating pin:', error);
    }
  };

  const getNumericCoords = (geoPoint: GeoPointModel | undefined) => {
    if (!geoPoint) return undefined;
    return {
      lat: typeof geoPoint.lat === 'string' ? parseFloat(geoPoint.lat) : geoPoint.lat,
      long: typeof geoPoint.long === 'string' ? parseFloat(geoPoint.long) : geoPoint.long
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setPinsWithRecordings([]);
      try {
        const data = await AllDataManager.observeProjectData(selectedProject);
        const filteredData = data.filter(pin => pin.project === selectedProject);
        setPinsWithRecordings(filteredData);
        setIsLoading(false);

        // Clear existing markers from the map and the ref before adding new ones
        markersRef.current.forEach((marker) => {
          marker.remove();
        });
        markersRef.current.clear();

        // Add markers for each pin and collect their positions
        const markers: L.Marker[] = [];
        filteredData.forEach(pin => {
          if (pin.location && pin.location.geoPoint && mapRef.current) {
            const iconUrl = constructUrl(pin.pinIcon);
            const icon = L.icon({
              iconUrl: iconUrl,
              iconSize: [70, 70],
              iconAnchor: [35, 70]
            });
            const lat = parseFloat(pin.location.geoPoint.lat as string);
            const long = parseFloat(pin.location.geoPoint.long as string);
            if (!isNaN(lat) && !isNaN(long)) {
              const marker = L.marker([lat, long], {
                icon: icon,
                draggable: true // Make the marker draggable
              }).addTo(mapRef.current);

              // Store the marker in the ref
              markersRef.current.set(pin.pinKey, marker);
              marker.on('dragstart', (event) => {
                const startPosition = event.target.getLatLng();
                setPinMoveHistory(prevHistory => [...prevHistory, { pin, prevPosition: startPosition }]);
              });
              // Add event listener for dragend event
              marker.on('dragend', async (event) => {
                const marker = event.target;
                const newPosition = marker.getLatLng();

                if (!pin.pinKey) {
                  console.error('Pin key is undefined, cannot update location.');
                  return;
                }

                // Update the pin's location in the state
                setPinsWithRecordings(prevPins => prevPins.map(p => {
                  if (p.pinKey === pin.pinKey) {
                    if (p.location) {
                      p.location.geoPoint = {
                        lat: newPosition.lat,
                        long: newPosition.lng
                      };
                    }
                    return { ...p };
                  }
                  return p;
                }));

                // Update the pin's location in Firestore using PinManager
                try {
                  await PinManager.updatePinLocation(pin.pinKey, newPosition.lat, newPosition.lng);
                } catch (error) {
                  console.error('Failed to update pin location in Firestore:', error);
                }
              });

              // Modify the click event to open PinEditor instead of navigating
              marker.on('click', () => {
                setEditingPin(pin);
              });

              markers.push(marker);
            } else {
              console.error('Latitude or longitude is not a valid number');
            }
          }
        });

        // If there are markers, fit the map view to their bounds with padding
        if (markers.length > 0 && mapRef.current) {
          const group = L.featureGroup(markers);
          const paddingOptions: L.FitBoundsOptions = { padding: [50, 50] as L.PointTuple }; // Cast as PointTuple
          mapRef.current.fitBounds(group.getBounds(), paddingOptions);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedProject]);

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map('interactiveMap').setView([52.4862, -1.8904], 13);
      mapRef.current = map;

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      // 2.1 Attach drag start event to icons
      const icons = document.querySelectorAll<HTMLElement>('[draggable="true"]');
      icons.forEach(icon => {
        icon.addEventListener('dragstart', (event: DragEvent) => {
          map.dragging.disable(); // Disable map dragging
          event.stopPropagation();

          if (event.dataTransfer && event.target) {
            const target = event.target as HTMLElement;
            if (target.dataset.icon) {
              event.dataTransfer.setData('text', target.dataset.icon);
            }
          }
        });
      });

      // Re-enable map dragging after drag operation is done
      map.getContainer().addEventListener('dragend', (event: DragEvent) => {
        map.dragging.enable();
      });


      map.getContainer().addEventListener('dragover', (event: DragEvent) => {
        event.preventDefault();
      });

      map.getContainer().addEventListener('drop', (event: DragEvent) => {
        event.preventDefault();
        map.dragging.enable(); // Re-enable map dragging
        if (event.dataTransfer) {
          const iconName = event.dataTransfer.getData('text');
          // Use constructUrl to get the correct icon URL
          let iconUrl = constructUrl(iconName);
          // Log the iconUrl for debugging

          // Check if the iconUrl is valid (this is a simple check, you might need a more robust solution)
          if (!iconUrl.startsWith('http')) {
            console.error('Invalid icon URL:', iconUrl);
            // Set a default iconUrl if the original one is invalid
            iconUrl = '/default-pin-icon@2x.png';
          }
          if (iconName) {
            const rect = map.getContainer().getBoundingClientRect();
            const x = event.clientX - rect.left; // Adjust for map container's left boundary
            const y = event.clientY - rect.top;  // Adjust for map container's top boundary
            const coords = map.containerPointToLatLng([x, y]);

            // Set the coordinates in the state and open the CreatePin component
            setNewPinCoords({ lat: coords.lat, long: coords.lng });
            setShowCreatePin(true);

            const icon = L.icon({
              iconUrl: iconUrl,
              iconSize: [70, 70]
            });

            // Create a new marker and add it to the map
            const marker = L.marker(coords, { icon: icon }).addTo(map);
            setTempMarker(marker); // Store the temporary marker
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedProjectData && selectedProjectData.icon) {
      const fullUrl = constructUrl(selectedProjectData.icon);
      setIconUrl(fullUrl);
    }
  }, [selectedProjectData]);

  const handleUndo = useCallback(() => {
    if (pinMoveHistory.length > 0 && mapRef.current) {
      const lastMove = pinMoveHistory[pinMoveHistory.length - 1];
      const { pin, prevPosition } = lastMove;
      const marker = markersRef.current.get(pin.pinKey);
      
      if (marker) {
        // Move the marker back to its previous position
        marker.setLatLng(prevPosition);

        // Update the pin's location in the state
        setPinsWithRecordings(prevPins => prevPins.map(p => {
          if (p.pinKey === pin.pinKey) {
            return {
              ...p,
              location: {
                ...p.location,
                geoPoint: {
                  lat: prevPosition.lat,
                  long: prevPosition.lng
                }
              }
            };
          }
          return p;
        }));

        // Update the pin's location in Firestore
        PinManager.updatePinLocation(pin.pinKey, prevPosition.lat, prevPosition.lng)
          .catch(error => console.error('Failed to update pin location in Firestore:', error));

        // Remove the last move from the history
        setPinMoveHistory(prevHistory => prevHistory.slice(0, prevHistory.length - 1));
      }
    }
  }, [pinMoveHistory]);

  return (
    <div className="map-view">
      <AdminNav
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        projectAdminHeight="unset"
        projectAdminPosition="unset"
        projectAdminTop="unset"
        projectAdminLeft="unset"
        projectName={projectName}
        userRole={userRole} // Pass the userRole state here
        projects={projects} // Pass the projects state if needed
        selectedProject={selectedProject} // Pass the selectedProject state if needed
        onProjectChange={onProjectChange} // Pass the onProjectChange function if needed
      />

      <ButtonRow
        settingsIcon={<Icon icon="settings" size={24} />}
        showLocationInput={true}
        showAddNewPinButton={false}
        handleMapViewClick={() => navigate("/map-view")}
        handleListViewClick={() => navigate("/pa-pins")}
        defaultView="map"
        userRole={userRole}
        centerMapOnLocation={centerMapOnLocation}
      />

      <div id="interactiveMap" style={{ height: "100%", width: "100%" }}></div>

      <button 
        className="undo-button" 
        onClick={handleUndo}
      >
        Undo Last Pin Move
      </button>

      <div className="icon-bar">
        <div className="label">
          <b className="enter-a-post">GEO</b>
          <img
            className="pin-icon"
            draggable="true"
            data-icon={selectedProjectData && selectedProjectData.icon ? selectedProjectData.icon : 'default-pin-icon'}
            alt=""
            src={selectedProjectData && selectedProjectData.icon ? constructUrl(selectedProjectData.icon) : '/default-pin-icon@2x.png'}
          />
        </div>
        {/* <div className="label">
          <b className="enter-a-post">RADIO</b>
          <img className="radio-pin-icon" draggable="true" data-icon="radio-pin" alt="" src="/radio-pin@2x.png" />
        </div>
        <div className="label">
          <b className="enter-a-post">QR</b>
          <img className="qr-square-icon" draggable="true" data-icon="qr-square" alt="" src="/qr-square@2x.png" />
        </div>
        <div className="label">
          <b className="enter-a-post">CONDUCTOR</b>
          <img className="conductor-icon" draggable="true" data-icon="conductor" alt="" src="/conductor@2x.png" />
         </div>*/}
        <b className="drag-instruction">Click and drag icons on to the map</b>
      </div>

      {showCreatePin && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          zIndex={1000}
          onOutsideClick={closeCreatePin}
        >
          <PinEditor
            onClose={closeCreatePin}
            onSave={handleSavePin}
            pinData={null}
            initialCoords={getNumericCoords(newPinCoords)}
          />
        </PortalPopup>
      )}

      {editingPin && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          zIndex={1000}
          onOutsideClick={closeEditPin}
        >
          <PinEditor
            onClose={closeEditPin}
            onSave={handleUpdatePin}
            pinData={editingPin}
            initialCoords={getNumericCoords(editingPin.location?.geoPoint)}
          />
        </PortalPopup>
      )}
    </div>
  );
});

export default MapView;
