import { db } from '../firebase/firebase';
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { FeatureFlags } from '../types/User';

export interface IFeatures {
  enableInAppRecording: boolean;
  enableWander: boolean;
}

export const FeaturesManager = {
  /**
   * Retrieves the current feature flags from the database.
   * @returns {Promise<IFeatures>} The feature flags.
   */
  getFeatures: async (): Promise<IFeatures> => {
    try {
      const featuresDocRef = doc(db, 'Features', 'globalFeatureSwitch');
      const docSnapshot = await getDoc(featuresDocRef);
      if (docSnapshot.exists()) {
        return docSnapshot.data() as IFeatures;
      } else {
        // Return default values if the document doesn't exist
        return { enableInAppRecording: false, enableWander: false };
      }
    } catch (error) {
      console.error("Error fetching features:", error);
      // Return default values in case of error
      return { enableInAppRecording: false, enableWander: false };
    }
  },

  /**
   * Sets up a listener for changes to the feature flags.
   * @param {(features: IFeatures) => void} callback - Function to call when features change.
   * @returns {() => void} A function to unsubscribe from the listener.
   */
  listenToFeatures: (callback: (features: IFeatures) => void): (() => void) => {
    const featuresDocRef = doc(db, 'Features', 'globalFeatureSwitch');
    return onSnapshot(featuresDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        callback(docSnapshot.data() as IFeatures);
      } else {
        callback({ enableInAppRecording: false, enableWander: false });
      }
    }, (error) => {
      console.error("Error listening to features:", error);
    });
  },

  /**
   * Retrieves the feature flags for a specific user.
   * @param {string} userId - The ID of the user.
   * @returns {Promise<FeatureFlags>} The user-specific feature flags.
   */
  getUserFeatureFlags: async (userId: string): Promise<FeatureFlags> => {
    try {
      const userDocRef = doc(db, 'Users', userId);
      const docSnapshot = await getDoc(userDocRef);
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        return userData.featureFlags || { enableInAppRecording: false, enableWander: false };
      } else {
        // Return default values if the document doesn't exist
        return { enableInAppRecording: false, enableWander: false };
      }
    } catch (error) {
      console.error("Error fetching user feature flags:", error);
      // Return default values in case of error
      return { enableInAppRecording: false, enableWander: false };
    }
  },

  /**
   * Updates a specific feature flag in the database.
   * @param {keyof IFeatures} feature - The feature to update.
   * @param {boolean} value - The new value for the feature.
   * @returns {Promise<void>}
   */
  updateFeature: async (feature: keyof IFeatures, value: boolean): Promise<void> => {
    try {
      const featuresDocRef = doc(db, 'Features', 'globalFeatureSwitch');
      await updateDoc(featuresDocRef, { [feature]: value });
    } catch (error) {
      console.error("Error updating feature:", error);
      throw error;
    }
  },
};