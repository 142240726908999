import { useEffect } from "react";
import { useLocalObservable } from "mobx-react-lite";
import WanderStore from "../stores/WanderStore";
import RecordingStore from "../stores/RecordingStore";
import { IWanderPin } from "../types";
import { IRecordingWithAuthor } from "../types"; // Assuming this import is necessary for the type correction
import { runInAction } from "mobx";

export const useWanderData = (wanderKey: string) => {
  const localStore = useLocalObservable(() => ({
    wanderData: {} as IWanderPin,
    recordings: [] as IRecordingWithAuthor[],
    loading: false,
    error: null as Error | null,

    async fetchWanderData() {
      this.loading = true;
      try {
        const data = await WanderStore.fetchWanderBypinKey(wanderKey);
        if (data) {
            const recordings = await RecordingStore.fetchRecordingsForPin(data.pinKey);
            runInAction(() => {
                this.wanderData = data;
                this.recordings = recordings;
                this.loading = false;
            });
        } else {
            runInAction(() => {
                this.loading = false;
                // Handle the case where data is undefined, possibly set an error message
            });
        }
      } catch (error) {
        runInAction(() => {
          this.error = error instanceof Error ? error : new Error('An unknown error occurred');
          this.loading = false;
        });
      }
    },

    async updateWander(updatedWander: IWanderPin) {
      await WanderStore.updateWander(updatedWander);
      runInAction(() => {
        if (this.wanderData && this.wanderData.pinKey === updatedWander.pinKey) {
          this.wanderData = updatedWander;
        }
      });
    },
  }));

  useEffect(() => {
    if (wanderKey) {
      localStore.fetchWanderData();
    }
  }, [wanderKey, localStore]);

  return localStore;
};