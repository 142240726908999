import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';


const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;

if (!apiKey) {
  throw new Error('Bugsnag API key is missing. Please add REACT_APP_BUGSNAG_API_KEY to your .env file.');
}

Bugsnag.start({
  apiKey, 
  plugins: [new BugsnagPluginReact()],
});

export const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);