import { v4 as uuidv4 } from 'uuid';
import { RecordingManager, AuthorManager, ProjectManager, TagManager } from "../models";
import { IRecording, IRecordingWithAuthor } from "../types";
import { handleFileUpload } from "../utilities";
import { storage } from '../firebase';
import { deleteObject, ref } from 'firebase/storage';
import { initiateTranscription } from './transcriptionService';
import { sendEmailNotification } from './emailService';
import QRCode from 'qrcode';
import { updateTags, addTagsToNewRecording } from '../utilities/tagUpdate';
import { SaveRecordingParams } from '../types/SaveRecordingParams';
import RecordingStore from '../stores/RecordingStore';


export const saveRecording = async (params: SaveRecordingParams): Promise<{ updatedRecording: IRecordingWithAuthor; logs: string[] }> => {
  const logs: string[] = [];
  const {
    recordingKey,
    authorDetails,
    recordingDetails,
    projectId,
    pinKey,
    albumKey,
    currentUserKey,
    sourceView,
    pinType,
    markerPosition,
    locationDescription,
    shouldInitiateTranscription
  } = params;

  try {
    logs.push('Save initiated');

    // 1. Handle author
    let authorKey = authorDetails.authorKey;
    let authorImagePath = authorDetails.existingImageUrl || '/assets/icons/user.svg';
    
    if (authorDetails.imageFile) {
      const timestampedFileName = `${Date.now()}-${authorDetails.imageFile.name}`;
      authorImagePath = await handleFileUpload(authorDetails.imageFile, () => {}, 'images/author/', timestampedFileName);
    }

    if (authorKey) {
      await AuthorManager.updateAuthor(authorKey, {
        name: authorDetails.name,
        bio: authorDetails.bio,
        website: authorDetails.website,
        image: authorImagePath,
        tags: { authorTags: authorDetails.tags }
      });
    } else {
      authorKey = uuidv4();
      await AuthorManager.createAuthor(authorKey, {
        name: authorDetails.name,
        bio: authorDetails.bio,
        website: authorDetails.website,
        image: authorImagePath,
        tags: { authorTags: authorDetails.tags },
        isClaimed: true,
        userKey: currentUserKey
      });
    }

    // 2. Handle recording file
    let recordingPath = '';
    if (recordingDetails.audioFile) {
      const timestampedFileName = `${Date.now()}-${recordingDetails.audioFile.name}`;
      recordingPath = await handleFileUpload(recordingDetails.audioFile, () => {}, `recordings/${projectId}/`, timestampedFileName);
      // Add a log here to confirm the path is set
      if (recordingPath) {
        logs.push(`Recording path set to: ${recordingPath}`);
      } else {
        logs.push(`No new recording path set`);
      }

      // Delete old file if updating
      if (recordingKey) {
        const oldRecording = await RecordingManager.getRecording(recordingKey);
        if (oldRecording?.file?.recordingPath) {
          const oldFileRef = ref(storage, oldRecording.file.recordingPath);
          try {
            await deleteObject(oldFileRef);
            logs.push('Old recording file deleted successfully');
          } catch (deleteError) {
            console.error('Error deleting old recording file:', deleteError);
            logs.push(`Error deleting old recording file: ${deleteError}`);
          }
        }
      }
    }

    // 3. Generate and upload QR code if pinType is 'qrCode'
    let qrCodeImagePath = '';
    if (pinType === 'qrCode') {
      qrCodeImagePath = await generateAndUploadQRCode(recordingKey || uuidv4());
    }

    // 4. Prepare recording object
    let existingRecording: IRecording | null = null;
    if (recordingKey) {
      existingRecording = await RecordingManager.getRecording(recordingKey);
      logs.push(`Existing recording data: ${JSON.stringify(existingRecording, null, 2)}`);
    }

    const updatedRecording: IRecording = {
      key: recordingKey || uuidv4(),
      albumKey: albumKey || null,
      file: {
        description: recordingDetails.description,
        audioURL: recordingDetails.audioURL || '',
        recordingPath: recordingPath || (existingRecording?.file?.recordingPath || ''),
        title: recordingDetails.title,
        size: recordingDetails.audioFile?.size || existingRecording?.file?.size || 0,
      },
      ownership: {
        recordingNarrator: recordingDetails.narrator,
        recordingAuthor: authorKey,
        recordingOwner: currentUserKey || '',
      },
      project: projectId,
      pinKey: pinKey || '',
      tags: {
        subjectTags: recordingDetails.subjectTags,
        genreTags: recordingDetails.genreTags,
      },
      whereQRFind: recordingDetails.whereQRFind || '',
      transcription: existingRecording?.transcription || '',
      timingData: existingRecording?.timingData || {},
      transcriptionResults: existingRecording?.transcriptionResults || [],
      qrPath: qrCodeImagePath || existingRecording?.qrPath || '',
    };

    logs.push(`Updated recording data before save: ${JSON.stringify(updatedRecording, null, 2)}`);

    // 5. Save or update recording
    if (recordingKey) {
      await RecordingManager.updateRecording(recordingKey, updatedRecording);
    } else {
      const key = await RecordingManager.createRecording(updatedRecording);
      if (!key) throw new Error('Failed to create new recording');
      updatedRecording.key = key;
    }

    // Update RecordingStore
    const updatedRecordingWithAuthor: IRecordingWithAuthor = {
      ...updatedRecording,
      author: {
        authorKey: authorKey,
        name: authorDetails.name,
        bio: authorDetails.bio,
        website: authorDetails.website,
        image: authorImagePath,
        tags: { authorTags: authorDetails.tags },
        isClaimed: true,
        userKey: currentUserKey
      }
    };

    if (recordingKey) {
      RecordingStore.updateRecording(recordingKey, updatedRecordingWithAuthor);
    } else {
      RecordingStore.addRecording(updatedRecordingWithAuthor);
    }

    // 6. Update tags
    const filteredAuthorTags = authorDetails.tags.filter(tag => tag.trim() !== '');
    const filteredSubjectTags = recordingDetails.subjectTags.filter(tag => tag.trim() !== '');
    const filteredGenreTags = recordingDetails.genreTags.filter(tag => tag.trim() !== '');

    if (recordingKey) {
      await updateTags({
        authorKey: updatedRecording.ownership?.recordingAuthor || undefined, // Ensure it's a string or undefined
        recordingKey: updatedRecording.key || '', // Ensure it's a string
        pinKeys: [pinKey || ''], // Ensure it's a string
        authorTags: filteredAuthorTags,
        initialAuthorTags: params.initialAuthorTags,
        subjectTags: filteredSubjectTags,
        initialSubjectTags: [], // Provide initial subject tags if available
        genreTags: filteredGenreTags,
        initialGenreTags: [] // Provide initial genre tags if available
      });
    } else {
      if (filteredAuthorTags.length > 0 || filteredSubjectTags.length > 0 || filteredGenreTags.length > 0) {
        await addTagsToNewRecording(
          updatedRecording.ownership?.recordingAuthor || '', // Ensure it's a string
          updatedRecording.key || '', // Ensure it's a string
          [pinKey || ''], // Ensure it's a string
          filteredAuthorTags,
          filteredSubjectTags,
          filteredGenreTags
        );
      }
    }

    // 7. Update project audio size
    if (recordingDetails.audioFile?.size && projectId) {
      await ProjectManager.updateProjectAudioSize(projectId, recordingDetails.audioFile.size);
    }

    // 8. Send email notification for 'Form' source view
    if (sourceView === 'Form' && projectId) {
      const locationDesc = markerPosition 
        ? `Latitude: ${markerPosition.lat}, Longitude: ${markerPosition.lng}`
        : locationDescription;

      const additionalInfo = `
        User: ${currentUserKey || 'Unknown'},
        Author: ${authorDetails.name || 'Unknown'},
        Recording Title: ${recordingDetails.title || 'Untitled'},
        Project ID: ${projectId || 'Unknown'}
      `;

      const emailBody = `
        The form has been submitted with the following details:
        - Location Description: ${locationDesc}
        - Additional Info: ${additionalInfo}
        - Logs: ${logs.join('\n')}
      `;

      if (locationDesc) {
        await sendEmailNotification(locationDesc, emailBody);
      } else {
        await sendEmailNotification('No location description', emailBody);
      }
    }

    // 9. Initiate transcription if needed
    if (shouldInitiateTranscription) {
      const filePathToTranscribe = updatedRecording.file?.recordingPath;
      if (filePathToTranscribe) {
        initiateTranscription({
          fileName: filePathToTranscribe,
          recordingId: updatedRecording.key,
          transcriptionText: updatedRecording.transcription || '',
          timingData: updatedRecording.timingData || {}
        }).catch(error => {
          console.error('Error initiating transcription:', error);
          logs.push(`Error initiating transcription: ${error}`);
        });
      } else {
        console.warn('No file path available for transcription');
        logs.push('No file path available for transcription');
      }
    }

    logs.push('Save successful');
    logs.push(`Final updated recording: ${JSON.stringify(updatedRecordingWithAuthor, null, 2)}`);
    return { updatedRecording: updatedRecordingWithAuthor, logs };

  } catch (error) {
    logs.push(`Error in save process: ${error}`);
    console.error('Error in save process:', error);

    // Send email with error logs
    const errorEmailBody = `
      An error occurred during the save process:
      - Error: ${error}
      - Logs: ${logs.join('\n')}
    `;
    await sendEmailNotification('Save Error Notification', errorEmailBody);

    throw error;
  }
};

const generateAndUploadQRCode = async (data: string): Promise<string> => {
  const qrCode = await QRCode.toDataURL(data, {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    renderer: {
      quality: 0.92
    }
  });
  return qrCode;
};

