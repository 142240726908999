// fileUpload.ts
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from '../firebase/storage'; 

export const handleFileUpload = async (
  file: File,
  setFileUrl: React.Dispatch<React.SetStateAction<string>>,
  filePath: string,
  fileName: string
): Promise<string> => {
  if (!file) throw new Error("No file provided");

  // Create a storage reference
  const storageRef = ref(storage, filePath + fileName);

  // Start the upload
  const uploadTask = uploadBytesResumable(storageRef, file);

  // Listen to state changes
  uploadTask.on('state_changed', 
    (snapshot) => {
      // You can use this to show the upload progress
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    }, 
    (error) => {
      // Handle unsuccessful uploads
      console.error('Upload failed:', error);
    }
  );

  // Wait for the upload to complete
  await new Promise((resolve, reject) => {
    uploadTask.on('state_changed', 
      () => {}, 
      (error) => reject(error), 
      async () => {
        // Get the download URL and set it
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setFileUrl(downloadURL);
        resolve(downloadURL); 
      }
    );
  });

  // Return the full path of the uploaded file
  return uploadTask.snapshot.ref.fullPath;
};