import React, { useEffect } from 'react';
import {
  TextField,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button
} from "@mui/material";
import TagInputField from './TagInputField';
import FormButtons from './FormButtons';
import { IPin, Location, GeoPointModel } from "../types";

interface PinFormProps {
  pinData: Partial<IPin>;
  isQREnabled: boolean;
  isSequentialEnabled: boolean;
  orderLogic: string;
  pinIconUrl: string;
  name: string | null;
  pinDescription: string | null;
  location: Location;
  radius: number | null;
  locationTags: string[];
  isProjectQREnabled: boolean;
  isProjectSequentialEnabled: boolean;
  onPinNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPinDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLocationChange: (fieldName: keyof GeoPointModel) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQrToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSequentialToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOrderLogicChange: (event: SelectChangeEvent<string>) => void;
  onRadiusChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setLocationTags: React.Dispatch<React.SetStateAction<string[]>>;
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
  isUploading: boolean;
  showDelete?: boolean;
}

const PinForm: React.FC<PinFormProps> = ({
  pinData,
  isQREnabled,
  isSequentialEnabled,
  orderLogic,
  pinIconUrl,
  name,
  pinDescription,
  location,
  radius,
  locationTags,
  isProjectQREnabled,
  isProjectSequentialEnabled,
  onPinNameChange,
  onPinDescriptionChange,
  onLocationChange,
  onQrToggleChange,
  onSequentialToggleChange,
  onOrderLogicChange,
  onRadiusChange,
  onFileChange,
  setLocationTags,
  onCancel,
  onSave,
  onDelete,
  isUploading,
  showDelete = false,
}) => {


  return (
    <>
      <h1 className="heading1">{pinData.pinKey ? 'Edit Pin' : 'Create Pin'}</h1>
      <a className="heading-2">Pin Information</a>
      <TextField
        className="input-container"
        color="primary"
        label="Name"
        size="medium"
        variant="filled"
        type="text"
        value={name || ''}
        onChange={onPinNameChange}
      />
      <div className="image-uploader">
        <img className="pinicon" alt="Pin Icon" src={pinIconUrl} />
        <Button
          className="button-action-submit23"
          color="primary"
          size="medium"
          variant="contained"
          component="label"
        >
          Select Unique Icon
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={onFileChange}
          />
        </Button>
      </div>
      {isProjectQREnabled && (
        <div className="toggle">
          <div className="check-toggle">
            <FormControlLabel
              className="toggle2"
              control={
                <Switch
                  color="primary"
                  checked={isQREnabled}
                  onChange={onQrToggleChange}
                />
              }
              label={undefined}
            />
          </div>
          <a className="qr-codes1">Use QR Codes?</a>
        </div>
      )}
      {isQREnabled && (
        <TextField
          className="input-container"
          color="primary"
          label="Pin Description (QR Only)"
          size="medium"
          variant="filled"
          type="text"
          value={pinDescription || ''}
          onChange={onPinDescriptionChange}
        />
      )}
      {isProjectSequentialEnabled && (
        <div className="toggle">
          <div className="check-toggle">
            <FormControlLabel
              className="toggle2"
              control={
                <Switch
                  color="primary"
                  checked={isSequentialEnabled}
                  onChange={onSequentialToggleChange}
                />
              }
              label={undefined}
            />
          </div>
          <a className="qr-codes1">Sequential Delivery</a>
        </div>
      )}
      {isSequentialEnabled && (
        <Select
          className="input-container"
          value={orderLogic}
          onChange={onOrderLogicChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          variant="filled"
        >
          <MenuItem value="" disabled>
            Select Order Logic
          </MenuItem>
          <MenuItem value="after">After Previous Pin</MenuItem>
          <MenuItem value="with">With Previous Pin</MenuItem>
        </Select>
      )}

      <div className="heading-31">Coordinates</div>
      <div className="lat-long1">
        <TextField
          className="long-input-container1"
          label="Latitude"
          variant="filled"
          type="text"
          value={location.geoPoint?.lat || ''}
          onChange={onLocationChange('lat')}
        />
        <TextField
          className="long-input-container1"
          color="primary"
          label="Longitude"
          size="medium"
          variant="filled"
          type="text"
          value={location.geoPoint?.long || ''}
          onChange={onLocationChange('long')}
        />
      </div>
      <TextField
        className="input-container"
        color="primary"
        label="Collection Distance"
        size="medium"
        variant="filled"
        type="text"
        value={radius !== null ? radius.toString() : ''}
        onChange={onRadiusChange}
      />
      <a className="location-tags1">Location Tags</a>
      <TagInputField
        tags={locationTags}
        setTags={setLocationTags}
        className='tag-input-container'
        chipContainerClassName='tag-chip-container'
      />
      <FormButtons
        onCancel={onCancel}
        onSave={onSave}
        onDelete={onDelete}
        isUploading={isUploading}
        showDelete={showDelete}
      />
    </>
  );
};

export default PinForm;