// storage.ts
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { app } from './firebase';

// Get a reference to the storage service
const storage = getStorage(app);

// Function to delete a file
export const deleteFile = async (filePath: string) => {
  const fileRef = ref(storage, filePath);
  try {
    await deleteObject(fileRef);
  } catch (error) {
    console.error(`Error deleting file ${filePath}:`, error);
    throw error; // Re-throw the error so it can be caught in the calling function
  }
};

export default storage;