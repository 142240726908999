import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { TextField, Chip } from '@mui/material';

type TagInputFieldProps = {
  tags: string[];
  setTags: (tags: string[] | ((prevTags: string[]) => string[])) => void;
  className?: string; 
  chipContainerClassName?: string;
  onTagsChange?: (newTags: string[]) => void; 
};

export const TagInputField: React.FC<TagInputFieldProps> = ({ 
  tags, 
  setTags, 
  onTagsChange, 
  className, 
  chipContainerClassName 
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ',' || event.key === 'Enter') {
      event.preventDefault();
      const value = inputValue.trim();
      if (value && !tags.includes(value)) {
        const newTags = [...tags, value];
        setTags(newTags); // Update the local state
        setInputValue(''); // Clear the input field
        onTagsChange?.(newTags); // Call the onTagsChange prop function
      }
    }
  };

  const handleDelete = (tagToDelete: string) => {
    const newTags = tags.filter(tag => tag !== tagToDelete);
    setTags(newTags);
    onTagsChange?.(newTags); // Call the onTagsChange prop function
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    // Call onTagsChange if it's provided and the input value is a tag (e.g., after a comma or enter key press)
    if (event.target.value.endsWith(',') || event.target.value.endsWith('Enter')) {
      const newTag = event.target.value.slice(0, -1).trim(); // Remove the comma or 'Enter' and trim whitespace
      if (newTag && !tags.includes(newTag)) {
        const newTags = [...tags, newTag];
        setTags(newTags);
        onTagsChange?.(newTags); // Call onTagsChange with the new tags array
        setInputValue(''); // Reset the input value
      }
    }
  };

  return (
    <div className={className}> {/* Apply the className prop here */}
      <TextField
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Type and press ',' to add tags"
        variant="outlined"
        fullWidth
      />
      <div className={chipContainerClassName}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDelete(tag)}
            style={{ margin: '5px' }}
          />
        ))}
      </div>
    </div>
  );
};

export default TagInputField;