import { FunctionComponent, useState, useEffect, useCallback } from "react";
import {AdminNav, Icon, AdminButtonRow} from "../components";
import { useNavigate } from "react-router-dom";
import "./ListPages.css";
import { observer } from "mobx-react-lite";
import pinStore from "../stores/PinStore"; 
import { useAdminNav } from "../hooks/useAdminNav";
import { IAdminMessage } from "../types/AdminMessage";
import adminMessageStore from "../stores/AdminMessageStore";
import MessageAccordion from "../components/Accordions/AdminMessageAccordion";
import ListHeader from '../components/ListHeader'; // Import ListHeader component

const AdminMessageView: FunctionComponent = observer(() => {
  const navigate = useNavigate();
  const [isCreateAdminMessageOpen, setCreateAdminMessageOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole
  } = useAdminNav();
  
  const closeCreateAdminMessage = useCallback(() => {
    setCreateAdminMessageOpen(false);
  }, []);

  const handleAdminMessageCreated = useCallback((newMessage: IAdminMessage) => {
    adminMessageStore.addMessage(newMessage); // Use an action from your MessageStore to add the pin
  }, []);

  useEffect(() => {
    const fetchAdminMessages = async () => {
      await adminMessageStore.loadAdminMessages();
      setIsLoading(adminMessageStore.isLoading);
      setError(adminMessageStore.error);
    };

    fetchAdminMessages();
  }, []);

  useEffect(() => {
  }, [isLoading]);

  useEffect(() => {
  }, [error]);

  useEffect(() => {
  }, [pinStore.pins]);

  useEffect(() => {
    // This will now react whenever the pins Map changes, which should be after setMessages is called
  }, [pinStore.pins, selectedProject]);

  return (
    <div className="list-page">
      <AdminNav
        showProjectDropdown={false} 
        imageDimensions="/assets/overhear-assets/images/ovh-logoartboard-12x-1.png"
        projectAdminHeight="unset"
        projectAdminPosition="unset"
        projectAdminTop="unset"
        projectAdminLeft="unset"
        projectName={projectName}
        userRole={userRole} // Pass the userRole state here
        projects={projects} // Pass the projects state if needed
        selectedProject={selectedProject} // Pass the selectedProject state if needed
        onProjectChange={onProjectChange} // Pass the onProjectChange function if needed
      />

      <AdminButtonRow 
         settingsIcon={<Icon icon="settings" size={24} />}
         handleAdminMessageViewClick={() => navigate("/message-view")}
         handleProjectViewClick={() => navigate("/project-view")}
         handleWandersViewClick={() => navigate("/wanders-view")}
         handleUserAccessViewClick={() => navigate("/users-access-view")}
         defaultView="project-view"
         userRole={userRole}
      />
      <ListHeader headers={['', 'Live', 'Admin Name', 'Title', 'File', '', '']} columnCount={7} /> {/* Pass columnCount */}
      <div >
        {error && <div>Error loading messages: {error.message}</div>}
        {adminMessageStore.adminMessages.length > 0 ? (
            adminMessageStore.adminMessages.map((adminMessage) => (
                <MessageAccordion
                key={adminMessage.pinKey}
                messageKey={adminMessage.pinKey || ''} // Fallback to an empty string if pinKey is undefined
                />
            ))
            ) : (
            <div>Add your first Message.</div>
            )}
        </div>   
    </div> 
  );
});

export default AdminMessageView;
