import React, { useState } from "react";
import { TextField } from "@mui/material";
import "./InputForms.css";
import { IAdminMessage } from "../types/AdminMessage";
import FormButtons from './FormButtons';
import RecordingUploadFields from './RecordingUploadFields';

export type AdminMessageFormProps = {
  adminMessage?: IAdminMessage;
  authorBio: string;
  recordingDescription: string;
  recordingTitle: string;
  recordingURL: string;
  selectedFile: File | null;
  selectedFileName: string | null;
  isUploading: boolean;
  onAuthorBioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRecordingDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRecordingTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRecordingDetailsChange: (details: {
    recordingURL: string;
    fileSize: number | undefined,
    file?: File,
    transcriptionText?: string,
    selectedButton: 'mp3' | 'url' | 'record'
  }) => void;
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
};

export const AdminMessageForm: React.FC<AdminMessageFormProps> = ({
  adminMessage,
  authorBio,
  recordingDescription,
  recordingTitle,
  recordingURL,
  selectedFile,
  selectedFileName,
  isUploading,
  onAuthorBioChange,
  onRecordingDescriptionChange,
  onRecordingTitleChange,
  onRecordingDetailsChange,
  onCancel,
  onSave,
  onDelete
}) => {
  // Determine initial button state
  const initialButtonState = (): 'mp3' | 'url' => {
    if (recordingURL) {
      return 'url';
    } else if (selectedFileName) {
      return 'mp3';
    }
    return 'mp3'; // Default to 'mp3' if no existing data
  };

  const [selectedButton, setSelectedButton] = useState<'mp3' | 'url' | 'record'>(initialButtonState());

  const handleRecordingDetailsChange = (details: {
    recordingURL: string;
    fileSize: number | undefined,
    file?: File,
    transcriptionText?: string,
    selectedButton: 'mp3' | 'url' | 'record'
  }) => {
    setSelectedButton(details.selectedButton);
    onRecordingDetailsChange(details);
  };

  return (
    <div className="popup-portal">
      <h1 className="header1">{adminMessage ? 'Edit Admin Message' : 'Create Admin Message'}</h1>
      <div className="author-information-container1">
        <a className="author-information2">Admin Information</a>
        <TextField
          className="input-container"
          color="primary"
          label="Info"
          size="medium"
          variant="filled"
          type="text"
          value={authorBio}
          onChange={onAuthorBioChange}
          multiline
          rows={4}
          style={{ minHeight: '50px', overflow: 'auto' }}
        />
      </div>
      <div className="recording-information-container">
        <a className="recording-information1">Recording Information</a>
        <RecordingUploadFields
          initialRecordingURL={recordingURL}
          initialFileSize={selectedFile?.size}
          initialFileName={selectedFileName || undefined}
          onRecordingDetailsChange={handleRecordingDetailsChange}
          uploadPath="Admin Messages/"
          showTranscription={false}
          selectedButton={selectedButton}
        />
        <TextField
          className="input-container"
          label="Message Name"
          variant="filled"
          value={recordingTitle}
          onChange={onRecordingTitleChange}
        />
        <TextField
          className="input-container"
          label="Additional Info"
          variant="filled"
          value={recordingDescription}
          onChange={onRecordingDescriptionChange}
          multiline
          rows={4}
          style={{ minHeight: '50px', overflow: 'auto' }}
        />
      </div>
      <FormButtons
        onCancel={onCancel}
        onSave={onSave}
        onDelete={onDelete}
        isUploading={isUploading}
        showDelete={!!adminMessage}
      />
    </div>
  );
};