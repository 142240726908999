// src/hooks/useProjectData.ts
import { useEffect } from "react";
import { useLocalObservable } from "mobx-react-lite";
import { projectStore } from "../stores/ProjectStore";
import { IProject } from "../types";
import { ProjectManager } from "../models/ProjectManager";
import { runInAction } from 'mobx';

export const useProjectData = (project_id: string) => {
  const localStore = useLocalObservable(() => ({
    projectData: null as IProject | null,
    loading: false,
    error: null as Error | null,

    async fetchProject() {
      this.loading = true;
      try {
        const projectData = await ProjectManager.getProject(project_id);
        runInAction(() => {
          this.projectData = projectData;
          this.error = null;
          this.loading = false;
        });
      } catch (e) {
        runInAction(() => {
          this.error = e as Error;
          this.loading = false;
        });
      }
    },

    async updateProject(updatedProject: IProject) {
      this.loading = true;
      try {
        await ProjectManager.updateProject(project_id, updatedProject);
        runInAction(() => {
          this.projectData = updatedProject;
          this.error = null;
          this.loading = false;
        });
      } catch (e) {
        runInAction(() => {
          this.error = e as Error;
          this.loading = false;
        });
      }
    },
  }));

  useEffect(() => {
    localStore.fetchProject();
    // Return a cleanup function if needed
    return () => {
      // Cleanup if any (e.g., aborting fetch requests)
    };
  }, [project_id, localStore]);

  return localStore;
};