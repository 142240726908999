import React, { useState, useCallback, useEffect } from 'react';
import { TextField, Button, CircularProgress } from "@mui/material";
import "./EditRecording.css"
import { format } from 'date-fns';

interface RecordingUploadFieldsProps {
    initialRecordingURL?: string;
    initialFileSize?: number;
    initialFileName?: string | null;
    initialTranscription?: string;  
    onRecordingDetailsChange: (details: { recordingURL: string; fileSize: number | undefined, file?: File, transcriptionText?: string, selectedButton: 'mp3' | 'url' | 'record' }) => void;
    uploadPath: string;
    showTranscription?: boolean;
    selectedButton: 'mp3' | 'url' | 'record';
}

const RecordingUploadFields: React.FC<RecordingUploadFieldsProps> = ({
    initialRecordingURL = '',
    initialFileSize,
    initialFileName = null,
    initialTranscription = '',  
    onRecordingDetailsChange,
    uploadPath,
    showTranscription = true,
    selectedButton: initialSelectedButton,
}) => {
    const [selectedButton, setSelectedButton] = useState<'mp3' | 'url' | 'record'>(initialSelectedButton);
    const [recordingURL, setRecordingURL] = useState(initialRecordingURL);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(initialFileName);
    const [fileSize, setFileSize] = useState<number | undefined>(initialFileSize);
    const [isUploading, setIsUploading] = useState(false);
    const [transcriptionText, setTranscriptionText] = useState<string>(initialTranscription);
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [isRecordingComplete, setIsRecordingComplete] = useState(false);

    useEffect(() => {
        setTranscriptionText(initialTranscription);
    }, [initialTranscription]);

    const handleRecordingDetailsChange = useCallback((details: {
        recordingURL: string;
        fileSize: number | undefined,
        file?: File,
        transcriptionText?: string,
        selectedButton: 'mp3' | 'url' | 'record'
    }) => {
        onRecordingDetailsChange({
            ...details,
            transcriptionText: transcriptionText // Always include the current transcription
        });
    }, [onRecordingDetailsChange, transcriptionText]);

    const handleRecordingURLChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const url = event.target.value;
        setRecordingURL(url);
        handleRecordingDetailsChange({
            recordingURL: url,
            fileSize: 0,
            selectedButton: 'url'
        });
    }, [handleRecordingDetailsChange]);

    const onMp3ButtonClick = useCallback(() => {
        setSelectedButton('mp3');
        setRecordingURL('');
        handleRecordingDetailsChange({
            recordingURL: '',
            fileSize: fileSize,
            selectedButton: 'mp3'
        });
    }, [fileSize, handleRecordingDetailsChange]);

    const onUrlButtonClick = useCallback(() => {
        setSelectedButton('url');
        setSelectedFile(null);
        setSelectedFileName(null);
        setFileSize(0);
        handleRecordingDetailsChange({
            recordingURL: recordingURL,
            fileSize: 0,
            selectedButton: 'url'
        });
    }, [recordingURL, handleRecordingDetailsChange]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            setSelectedFileName(file.name);
            setFileSize(file.size);
            handleRecordingDetailsChange({
                recordingURL: '',
                file: file,
                fileSize: file.size,
                selectedButton: 'mp3'
            });
        } else {
            setSelectedFile(null);
            setSelectedFileName(null);
            setFileSize(0);
            handleRecordingDetailsChange({
                recordingURL: recordingURL,
                fileSize: 0,
                selectedButton: 'url'
            });
        }
    }, [handleRecordingDetailsChange]);

    const handleTranscriptionTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setTranscriptionText(text);
        handleRecordingDetailsChange({
            recordingURL: recordingURL,
            fileSize: fileSize,
            transcriptionText: text,
            selectedButton: selectedButton
        });
    }, [recordingURL, fileSize, handleRecordingDetailsChange, selectedButton]);

    useEffect(() => {
        setSelectedButton(initialSelectedButton);
        if (initialSelectedButton === 'url' && initialRecordingURL) {
            setRecordingURL(initialRecordingURL);
        } else if (initialSelectedButton === 'mp3' && initialFileName) {
            setSelectedFileName(initialFileName);
            setFileSize(initialFileSize);
        }
    }, [initialSelectedButton, initialRecordingURL, initialFileName, initialFileSize]);

    const onRecordButtonClick = useCallback(() => {
        setSelectedButton('record');
        setRecordingURL('');
        setSelectedFile(null);
        setSelectedFileName(null);
        setFileSize(0);
        handleRecordingDetailsChange({
            recordingURL: '',
            fileSize: 0,
            selectedButton: 'record'
        });
    }, [handleRecordingDetailsChange]);

    const startRecording = useCallback(async () => {
        if (isRecordingComplete) {
            const confirmReRecord = window.confirm("Are you sure you want to re-record? This will overwrite your current recording.");
            if (!confirmReRecord) {
                return;
            }
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            const chunks: BlobPart[] = [];

            recorder.ondataavailable = (e) => chunks.push(e.data);
            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: 'audio/mp3' });
                setAudioBlob(blob);
                
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                const fileName = `${currentDate}_recorded_audio.mp3`;
                
                const file = new File([blob], fileName, { type: 'audio/mp3' });
                setSelectedFile(file);
                setSelectedFileName(fileName);
                setFileSize(blob.size);
                handleRecordingDetailsChange({
                    recordingURL: '',
                    file: file,
                    fileSize: blob.size,
                    selectedButton: 'record'
                });
                setIsRecordingComplete(true);
            };

            setMediaRecorder(recorder);
            recorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    }, [handleRecordingDetailsChange, isRecordingComplete]);

    const stopRecording = useCallback(() => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    }, [mediaRecorder]);

    return (
        <div className="recording-upload-container">
            <div className="fileurl1">
                <button 
                    className={`mp3-button ${selectedButton === 'mp3' ? 'selected' : ''}`} 
                    onClick={onMp3ButtonClick}
                >
                    <b className="file-upload">File Upload</b>
                </button>
                {/* <button 
                    className={`url-button ${selectedButton === 'url' ? 'selected' : ''}`} 
                    onClick={onUrlButtonClick}
                >
                    <b className="url">URL</b>
                </button> */}
                <button 
                    className={`record-button ${selectedButton === 'record' ? 'selected' : ''}`} 
                    onClick={onRecordButtonClick}
                >
                    <b className="record">Record</b>
                </button>
            </div>
            {selectedButton === 'mp3' && (
                <>
                    <div style={{ fontSize: 14, color: 'black' }}>
                        {selectedFileName ? `Selected File: ${selectedFileName}` : 'No file selected'}
                    </div>
                    <Button
                        color="primary"
                        size="medium"
                        variant="contained"
                        component="label"
                    >
                        {selectedFileName ? 'Change File' : 'Select File'}
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>
                </>
            )}
            {/* {selectedButton === 'url' && (
                <>
                    <div className='url-instructions'>
                        Ensure the url ends with a file<br />
                        e.g. 'www.website.com/content/audiofile.mp3'
                    </div>
                    <TextField
                        label="Recording URL"
                        variant="filled"
                        fullWidth
                        value={recordingURL}
                        onChange={handleRecordingURLChange}
                    />
                </>
            )} */}
            {selectedButton === 'record' && (
                <>
                    <div style={{ fontSize: 14, color: 'black' }}>
                        {isRecording ? 'Recording...' : (audioBlob ? 'Recording complete' : 'Ready to record')}
                    </div>
                    <Button
                        color="error"
                        size="medium"
                        variant="contained"
                        component="label"
                        onClick={isRecording ? stopRecording : startRecording}
                    >
                        {isRecording ? 'Stop Recording' : (isRecordingComplete ? 'Re-Record' : 'Start Recording')}
                    </Button>
                    {audioBlob && (
                        <audio controls src={URL.createObjectURL(audioBlob)} />
                    )}
                </>
            )}
            {showTranscription && (
                <TextField
                    label="Transcription Text"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={4}
                    value={transcriptionText}
                    onChange={handleTranscriptionTextChange}
                    placeholder="Paste your transcription text here"
                />
            )}
            <div className="buttons">
                {isUploading ? (
                    <Button
                        onClick={() => setIsUploading(false)} // Example button to stop upload
                        disabled={!isUploading}
                    >
                        {isUploading ? (
                            <CircularProgress size={24} style={{ color: 'blue' }} />
                        ) : (
                            <b>Cancel Upload</b>
                        )}
                    </Button>
                ) : (<div></div>)}
            </div>
        </div>
    );
};

export default RecordingUploadFields;