import * as React from 'react';
import { CSSProperties, FunctionComponent } from 'react';

export type IconTypes = keyof typeof iconRegistry;

interface IconProps {
  /**
   * The name of the icon
   */
  icon: IconTypes | string;

  /**
   * An optional tint color for the icon
   */
  color?: string;

  /**
   * An optional size for the icon. If not provided, the icon will be sized to the icon's resolution.
   */
  size?: number;

  /**
   * Style overrides for the icon image
   */
  style?: CSSProperties;

  /**
   * An optional function to be called when the icon is pressed
   */
  onClick?: () => void;

  className?: string;

  /**
   * An optional width for the icon. If not provided, the icon will use the size prop or the icon's resolution.
   */
  width?: number;

  /**
   * An optional height for the icon. If not provided, the icon will use the size prop or the icon's resolution.
   */
  height?: number;
}

/**
 * A component to render a registered icon.
 * It is wrapped in a <button /> if `onClick` is provided, otherwise a <span />.
 */
export const Icon: FunctionComponent<IconProps & React.HTMLAttributes<HTMLSpanElement>> = ({
  icon,
  color,
  size,
  style,
  onClick,
  className,
  width,
  height,
  ...rest // rest parameter to capture other props like dragHandleProps
}) => {
  const iconSource = getIconSource(icon);
  const isClickable = !!onClick;
  const Wrapper = isClickable ? 'button' : 'span';

  const imageStyle: CSSProperties = {
    ...style,
    ...(color && { filter: `invert(${color})` }),
    width: width || size, // Use width prop if provided, otherwise fallback to size
    height: height || size, // Use height prop if provided, otherwise fallback to size
    userSelect: 'none',
  };

  if (isClickable) {
    return (
      <button {...rest} className={className} onClick={onClick} style={{ ...$imageStyleBase, ...style }}>
        <img src={iconSource} alt={icon} style={imageStyle} />
      </button>
    );
  } else {
    return (
      <span {...rest} className={className} style={{ ...$imageStyleBase, ...style }}>
        <img src={iconSource} alt={icon} style={imageStyle} />
      </span>
    );
  }
};

export const iconRegistry = {
  back: "assets/icons/back.png",
  lock: "assets/icons/lock.png",
  menu: "assets/icons/menu.png",
  more: "assets/icons/more.png",
  settings: "assets/icons/settings.png",
  view: "assets/icons/view.png",
  x: "assets/icons/x.png",
  overhearlight: "assets/icons/overhearlight.png",
  serveroverhearlight: "https://invite-service-dot-overhear-2.uc.r.appspot.com/assets/icons/overhearlight.png",
  overheardark: "assets/icons/overheardark.png",
  play: "assets/icons/playbutton.png",
  pause: "assets/icons/pausebutton.png",
  chevron: "assets/icons/chevron.png",
  google: "assets/icons/googleicon.png",
  locationpin: "assets/icons/locationpin.png",
  locationuser: "assets/icons/locationuser.png",
  scanicon: "assets/icons/scan-icon.png",
  burger: "assets/icons/burgericon.png",
  trash: "assets/icons/trash.png",
  search: "assets/icons/search-icon.png",
  download: "assets/icons/download.png",
  forward15: "assets/icons/forward-15-seconds.png",
  backward15: "assets/icons/backward-15-seconds.png",
  maptab: "assets/icons/maptab.png",
  librarytab: "assets/icons/librarytab.png",
  microphone: "assets/icons/microphone.png",
  checkboxchecked: "assets/icons/checkboxchecked.png",
  checkboxunchecked: "assets/icons/checkboxunchecked.png",
}

function getIconSource(icon: string): string {
  // This function should return the path to the icon image file
  // For example:
  return iconRegistry[icon as IconTypes];
}

const $imageStyleBase: CSSProperties = {
  // Define base styles for images here
};
