import { FunctionComponent, useState, useCallback, useEffect } from "react";
import ProjectEditor from "./ProjectEditor";
import PortalPopup from "./PortalPopup";
import "./ButtonRow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IProject } from "../types";
import { IAdminMessage } from "../types/AdminMessage";
import CreateWanders from "./CreateWander";
import { IWanderPin } from "../types/WanderPin";
import AdminMessageEditor from "./AdminMessageEditor";
import { Icon } from "./Icon";

type AdminButtonRowType = {
  settingsIcon?: React.ReactNode;
  showAddNewProjectButton?: boolean;
  showLocationInput?: boolean;
  defaultView?: 'message-view' | 'project-view';
  userRole?: string;
  /** Action props */
  
  onAdminMessageViewButtonClick?: () => void;
  onProjectViewButtonClick?: () => void;
  onWandersViewButtonClick?: () => void;
  handleAdminMessageViewClick: any,
  handleProjectViewClick: any,
  handleWandersViewClick: any,
  handleUserAccessViewClick: any, // Add this new prop
};

export const AdminButtonRow: FunctionComponent<AdminButtonRowType> = ({
  defaultView,
  userRole,
}) => {
  const navigate = useNavigate();
  const [isCreateProjectOpen, setCreateProjectOpen] = useState(false);
  const [isCreateAdminMessageOpen, setCreateAdminMessageOpen] = useState(false);
  const [isCreateWandersOpen, setCreateWandersOpen] = useState(false);
  const [activeView, setActiveView] = useState<'message-view' | 'project-view' | 'wanders-view' | 'user-access-view'>(defaultView || 'project-view');

  const handleAdminMessageViewClick = useCallback(() => {
    setActiveView('message-view');
    navigate("/message-view", { state: { userRole } });
  }, [navigate, userRole]);

  const handleProjectViewClick = useCallback(() => {
    setActiveView('project-view');
    navigate("/project-view", { state: { userRole } });
  }, [navigate, userRole]);

  const handleWandersViewClick = useCallback(() => {
    setActiveView('wanders-view');
    navigate("/wanders-view", { state: { userRole } });
  }, [navigate, userRole]);

  const handleUserAccessViewClick = useCallback(() => {
    setActiveView('user-access-view');
    navigate("/user-access-view", { state: { userRole } });
  }, [navigate, userRole]);

  const openCreateProject = useCallback(() => {
    setCreateProjectOpen(true);
  }, []);

  const closeCreateProject = useCallback(() => {
    setCreateProjectOpen(false);
  }, []);

  const handleProjectCreated = useCallback(async (newProject: IProject) => {
    // Handle the project creation logic here
    // For example, you might want to add the new project to a list or update the state
  }, []);

  const openCreateAdminMessage = useCallback(() => {
    setCreateAdminMessageOpen(true);
  }, []);

  const closeCreateAdminMessage= useCallback(() => {
    setCreateAdminMessageOpen(false);
  }, []);
  
  const handleAdminMessageCreated = useCallback((newMessage: IAdminMessage) => {
    // Handle the admin message creation logic here
  }, []);

  const openCreateWanders = useCallback(() => {
    setCreateWandersOpen(true);
  }, []);

  const closeCreateWanders = useCallback(() => {
    setCreateWandersOpen(false);
  }, []);

  const handleWandersCreated = useCallback((newWanders: IWanderPin) => {
    // Handle the wanders creation logic here
  }, []);
  
  const location = useLocation();

  useEffect(() => {
    // Check the current pathname and update the activeView state accordingly
    if (location.pathname === '/message-view') {
      setActiveView('message-view');
    } else if (location.pathname === '/project-view') {
      setActiveView('project-view');
    } else if (location.pathname === '/wanders-view') {
      setActiveView('wanders-view');
    } else if (location.pathname === '/user-access-view') { // Add this condition
      setActiveView('user-access-view');
    }
  }, [location]);
  
  return (
    <div className="button-row-container">
      <div className="button-row-grid">
        <div className="left-section">
          {/* Left section is now empty */}
        </div>
        
        <div className="center-section">
          <div className="toggle">
            <button 
              className={`view-button left ${activeView === 'message-view' ? 'active' : 'inactive'}`} 
              onClick={handleAdminMessageViewClick} 
            >
              <b>Admin Messages</b>
            </button>
            <button 
              className={`view-button middle ${activeView === 'project-view' ? 'active' : 'inactive'}`} 
              onClick={handleProjectViewClick}
            >
              <b>Project List</b>
            </button>
            <button 
              className={`view-button middle ${activeView === 'wanders-view' ? 'active' : 'inactive'}`} 
              onClick={handleWandersViewClick}
            >
              <b>Wanders List</b>
            </button>
            <button 
              className={`view-button right ${activeView === 'user-access-view' ? 'active' : 'inactive'}`} 
              onClick={handleUserAccessViewClick}
            >
              <b>User Access</b>
            </button>
          </div>
        </div>
        
        <div className="right-section">
          {activeView === 'project-view' && (
            <button className="button-action-submit" onClick={openCreateProject}>
              Add New Project
            </button>
          )}
          {activeView === 'message-view' && (
            <button className="button-action-submit" onClick={openCreateAdminMessage}>
              Add New Admin Message
            </button>
          )}
          {activeView === 'wanders-view' && (
            <button className="button-action-submit" onClick={openCreateWanders}>
              Add New Wander
            </button>
          )}

        </div>
      </div>

      {isCreateProjectOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeCreateProject}
        >
          <ProjectEditor 
            onClose={closeCreateProject} 
            onSave={handleProjectCreated}
          />
        </PortalPopup>
      )}
       {isCreateAdminMessageOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeCreateAdminMessage}
        >
          <AdminMessageEditor 
            onClose={closeCreateAdminMessage} 
            onSave={async (newMessage: IAdminMessage) => {
              handleAdminMessageCreated(newMessage);
              closeCreateAdminMessage();
            }}
          />
        </PortalPopup>
      )}
             {isCreateWandersOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeCreateWanders}
        >
          <CreateWanders 
          onClose={closeCreateWanders} 
          onSave={handleWandersCreated}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default AdminButtonRow;
