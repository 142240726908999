import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RecordingEditor from '../components/RecordingEditor';
import { ProjectManager } from '../models/ProjectManager';
import AuthorManager from '../models/AuthorManager';
import UserManager from '../models/UserManager';
import './FormsView.css'
import "../components/EditRecording.css";
import { TextField, Modal, Box } from '@mui/material';
import RecordingStore from '../stores/RecordingStore';
import authenticationStore from '../stores/AuthenticationStore';
import { IAuthor } from '../types/Author';
import { TermsAndConditions } from '../components';

const FormsView: React.FC = observer(() => {
  const { project_id } = useParams<{ project_id: string }>();
  const [config, setConfig] = useState({
    locationInfo: true,
    authorInfo: true,
    recordingInfo: true,
    uploadFields: true,
    password: ''
  });
  const [inputPassword, setInputPassword] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [authorData, setAuthorData] = useState<IAuthor | null>(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const fetchConfig = async () => {
    if (project_id) {
      const projectConfig = await ProjectManager.getProjectConfig(project_id);
      if (projectConfig) {
        setConfig(projectConfig);
      }
    }
  };

  const fetchAuthorData = async () => {
    if (authenticationStore.isAuthenticated && authenticationStore.user) {
      const user = await UserManager.getCurrentUser(authenticationStore.user.uid);
      if (user && user.userProfile.authorKey) {
        const author = await AuthorManager.getAuthor(user.userProfile.authorKey);
        if (author) {
          setAuthorData(author);
        }
      }
    }
  };

  useEffect(() => {
    fetchConfig();
    fetchAuthorData();
  }, [project_id, authenticationStore.isAuthenticated, authenticationStore.user]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value);
  };

  const handleSubmitPassword = async () => {
    setPasswordSubmitted(true);
    await fetchConfig();
    if (!isPasswordCorrect()) {
      alert('Incorrect password. Please try again.');
    } else {
      setOpenTermsModal(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitPassword();
    }
  };

  const isPasswordCorrect = () => {
    const isCorrect = inputPassword === config.password;
    return isCorrect;
  };

  const handleClose = () => {
    // Handle closing the RecordingEditor if needed
  };

  const handleRecordingAdded = () => {
    // You can add any additional logic here if needed
  };


  const handleSave = async (updatedRecording: any) => {
    try {
      const user = authenticationStore.user;
      if (user && authorData) {
        updatedRecording.authorId = authorData.authorKey;
      }
      await RecordingStore.addRecording(updatedRecording);
      handleRecordingAdded();
    } catch (error) {
      console.error('Error saving recording:', error);
      alert('Error saving recording. Please try again.'); // Show alert on error
    }
  };

  const handleTermsAgreement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(event.target.checked);
  };

  const handleContinueAfterTerms = () => {
    setTermsAgreed(true);
    setOpenTermsModal(false); // Close the modal
  };

  return (
    <div>

      {!passwordSubmitted || !isPasswordCorrect() ? (
        <div className='password-container'>
          <label>Enter Password to Access Form:</label>
          <TextField
            type="password"
            onChange={handlePasswordChange}
            value={inputPassword}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSubmitPassword}>Submit</button> 
        </div>
      ) : (
        <RecordingEditor
          onClose={handleClose}
          recordingKey={undefined}
          recording={undefined}
          sourceView="Form"
          config={config}
          project_id={project_id}
          onRecordingAdded={handleRecordingAdded}
          onSave={handleSave}
          prefillAuthorData={authorData}
        />
      )}

      <Modal
        open={openTermsModal}
        onClose={() => {}}
        aria-labelledby="terms-and-conditions-modal"
        aria-describedby="terms-and-conditions-description"
      >
        <Box className="modal-box">
          <TermsAndConditions
            agreed={termsAgreed}
            onAgree={handleTermsAgreement}
            onContinue={handleContinueAfterTerms}
          />
        </Box>
      </Modal>
    </div>
  );
});

export default FormsView;