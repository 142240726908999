// src/stores/UserStore.ts
import { makeAutoObservable } from "mobx";

interface IUser {
  userId: string;
  userRole: string;
}

export class userStore {
  user: IUser | null = null;
  loading = false;
  error: Error | null = null;

  constructor() {
    makeAutoObservable(this);
    this.loadUserFromLocalStorage();
  }

  setUser(user: IUser) {
    this.user = user;
    localStorage.setItem('userRole', user.userRole); // Save to local storage
  }

  clearUser() {
    this.user = null;
    localStorage.removeItem('userRole'); // Clear from local storage
  }

  loadUserFromLocalStorage() {
    const userRole = localStorage.getItem('userRole');
    if (userRole) {
      // Assuming you have a way to retrieve the full user object from just the role
      // Otherwise, you might want to store the entire user object in localStorage
      this.user = { userId: '', userRole }; // Populate with actual userId if needed
    }
  }

  get userRole(): string {
    return this.user ? this.user.userRole : '';
  }

  // Add methods for fetching, updating, etc. as needed
  // ...
}

export default new userStore();
