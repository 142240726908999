// src/stores/WanderStore.ts
import { makeAutoObservable, runInAction } from "mobx";
import { IWanderPin } from "../types/WanderPin";
import { WanderManager } from "../models/WanderManager";

export class WanderStore {
    wanders = new Map<string, IWanderPin>();
    loading = false;
    error: Error | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchAllWanders() {
        this.loading = true;
        this.error = null;
        try {
            const allWanders = await WanderManager.fetchAllWanders();
            runInAction(() => {
                this.wanders.clear();
                allWanders.forEach(wander => {
                    this.wanders.set(wander.wanderKey, wander);
                });
                runInAction(() => {
                    this.loading = true; // or false, depending on the context
                });
            });
        } catch (error) {
            runInAction(() => {
                console.error("Failed to fetch all wanders:", error);
                this.error = error instanceof Error ? error : new Error('An unknown error occurred');
                this.loading = false;
            });
        }
    }

    setWanders(wanders: IWanderPin[]) {
        runInAction(() => {
            this.wanders.clear();
            wanders.forEach(wander => {
                this.wanders.set(wander.wanderKey, wander);
            });
        });
    }

    addWander(newWander: IWanderPin) {
        this.wanders.set(newWander.wanderKey, newWander);
    }

    removeWander(wanderKey: string) {
        this.wanders.delete(wanderKey);
    }

    clearWanders() {
        this.wanders.clear();
    }

    async fetchWanderBypinKey(wanderKey: string): Promise<IWanderPin | null> {
        this.loading = true;
        this.error = null;
        try {
            let wander = await WanderManager.getWander(wanderKey);
            // No need to convert null to undefined, ensure the method returns null if not found
            runInAction(() => {
                if (wander !== null) {
                    this.wanders.set(wanderKey, wander);
                }
                this.loading = false;
            });
            return wander;
        } catch (error) {
            runInAction(() => {
                console.error(`Failed to fetch wander with wanderKey ${wanderKey}:`, error);
                this.error = error instanceof Error ? error : new Error('An unknown error occurred');
                this.loading = false;
            });
            return null;
        }
    }

    async createWander(newWander: IWanderPin) {
        this.loading = true;
        this.error = null;
        try {
            await WanderManager.createWander(newWander);
            runInAction(() => {
                this.wanders.set(newWander.wanderKey, newWander);
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error instanceof Error ? error : new Error('An unknown error occurred');
                this.loading = false;
            });
            console.error('Error creating new wander:', error);
            throw error;
        }
    }

    async fetchWander(wanderKey: string) {
        this.loading = true;
        this.error = null;
        try {
            const wander = await WanderManager.getWander(wanderKey);
            runInAction(() => {
                if (wander) {
                    this.wanders.set(wanderKey, wander);
                }
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => {
                console.error(`Failed to fetch wander with wanderKey ${wanderKey}:`, error);
                this.error = error instanceof Error ? error : new Error('An unknown error occurred');
                this.loading = false;
            });
        }
    }

    async updateWander(updatedWander: IWanderPin) {
        this.loading = true;
        this.error = null;
        try {
            await WanderManager.updateWander(updatedWander);
            runInAction(() => {
                this.wanders.set(updatedWander.wanderKey, updatedWander);
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error instanceof Error ? error : new Error('An unknown error occurred');
                this.loading = false;
            });
            console.error('Error updating wander:', error);
            throw error;
        }
    }

    async deleteWander(wanderKey: string) {
        this.loading = true;
        this.error = null;
        try {
            await WanderManager.deleteWander(wanderKey);
            runInAction(() => {
                this.wanders.delete(wanderKey); // Remove the Wander from the store
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.error = error instanceof Error ? error : new Error('An unknown error occurred');
                this.loading = false;
            });
            console.error('Error deleting wander:', error);
            throw error;
        }
    }

    getWander(wanderKey: string): IWanderPin | undefined {
        return this.wanders.get(wanderKey);
    }

    setWander(wanderKey: string, wander: IWanderPin) {
        this.wanders.set(wanderKey, wander);
    }
}

export default new WanderStore();