// transcriptionService.ts

import { getAuth } from 'firebase/auth';

interface TranscriptionParams {
    fileName: string;
    recordingId: string;
    transcriptionText?: string;
    timingData?: any; // Add this line
    languageCode?: string; // Add language code option
}

const TRANSCRIPTION_URL = 'https://europe-west2-overhear-2.cloudfunctions.net/speechToText/speech-to-text';

const fetchWithRetry = async (url: string, options: RequestInit, retries: number = 5, delay: number = 2000): Promise<Response> => {
    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                return response;
            }
            const errorText = await response.text();
            console.error(`Attempt ${i + 1} failed: ${response.status} ${response.statusText}`, errorText);
            if (i < retries - 1) {
                console.log(`Retrying in ${delay/1000} seconds...`);
                await new Promise(res => setTimeout(res, delay));
            }
        } catch (error) {
            console.error(`Attempt ${i + 1} failed:`, error);
            if (i < retries - 1) {
                console.log(`Retrying in ${delay/1000} seconds...`);
                await new Promise(res => setTimeout(res, delay));
            }
        }
    }
    throw new Error(`Failed to fetch ${url} after ${retries} retries`);
};

export const initiateTranscription = async ({ 
    fileName, 
    recordingId, 
    transcriptionText, 
    timingData,
    languageCode = 'en-GB' // Default to British English
}: TranscriptionParams): Promise<any> => {
    try {
        if (!fileName) {
          throw new Error('Recording path is undefined');
        }
        
        // Get the current user's ID token
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found');
        }
        const idToken = await user.getIdToken();

        console.log('Initiating transcription with params:', { fileName, recordingId, languageCode });

        const transcriptionResponse = await fetchWithRetry(TRANSCRIPTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ 
                fileName, 
                recordingId, 
                transcriptionText,
                timingData,
                languageCode // Add language code to the request
            }),
        });

        if (!transcriptionResponse.ok) {
            const errorText = await transcriptionResponse.text();
            console.error('Transcription service error:', transcriptionResponse.status, errorText);
            throw new Error(`HTTP error! status: ${transcriptionResponse.status}, message: ${errorText}`);
        }

        const data = await transcriptionResponse.json();
        console.log('Transcription initiated successfully:', data);
        return data;
    } catch (error) {
        console.error('Error initiating transcription:', error);
        throw error;
    }
  };
