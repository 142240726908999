import React from 'react';
import { CircularProgress } from "@mui/material";

interface FormButtonsProps {
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
  isUploading: boolean;
  showDelete?: boolean;
}

const FormButtons: React.FC<FormButtonsProps> = ({
  onCancel,
  onSave,
  onDelete,
  isUploading,
  showDelete = false
}) => {
  return (
    <>

      <div className="buttons">
        <button className="button-action-cancel" onClick={onCancel}>
          <b className="cancel">Cancel</b>
        </button>
        <button className="button-action-save" onClick={onSave} disabled={isUploading}>
          {isUploading ? (
            <CircularProgress size={24} style={{ color: 'black' }} />
          ) : (
            <b className="save">Save</b>
          )}
        </button>
      </div>
      {showDelete && (
        <button className="button-action-delete" onClick={onDelete}>
          <b className="delete">Delete</b>
        </button>
      )}
    </>
  );
};

export default FormButtons;