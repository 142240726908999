import { deleteFile } from "../firebase/storage";
import { IRecordingWithAuthor } from "../types";
import { RecordingManager, TagManager, ProjectManager } from "../models";
import recordingStore from "../stores/RecordingStore";

const STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

export async function handleDelete(
  recordingKey: string,
  pinKey?: string,
  onClose?: () => void,
  setIsUploading?: (isUploading: boolean) => void,
  recording?: IRecordingWithAuthor,
  fileSize?: number
) {
  if (!recordingKey) return;

  const confirmDelete = window.confirm('Are you sure you want to delete this recording?');
  if (confirmDelete) {
    setIsUploading?.(true);
    try {
      
      

      // Delete QR code image if it exists
      if (recording?.qrPath) {
        const fullQRPath = `gs://${STORAGE_BUCKET}/${recording.qrPath}`;
        
        await deleteFile(fullQRPath);
        
      }

      // Update project audio size
      if (fileSize && recording?.project) {
        await ProjectManager.updateProjectAudioSize(recording.project, -fileSize);
      }

      // Remove tags
      if (recording?.author?.tags?.authorTags && pinKey) {
        for (const tag of recording.author.tags.authorTags) {
          await TagManager.removePinKeyFromAuthorTag(tag, pinKey);
        }
      }
      if (recording?.tags?.subjectTags && pinKey) {
        for (const tag of recording.tags.subjectTags) {
          await TagManager.removeRecordingKeyFromTag(tag, recordingKey, pinKey);
        }
      }
      if (recording?.tags?.genreTags && pinKey) {
        for (const tag of recording.tags.genreTags) {
          await TagManager.removeRecordingKeyFromGenreTag(tag, recordingKey, pinKey);
        }
      }

      // Delete the audio file from storage
      if (recording?.file?.recordingPath) {
        const fullPath = `gs://${STORAGE_BUCKET}/${recording.file.recordingPath}`;
        
        try {
          await deleteFile(fullPath);
          
        } catch (error) {
          console.error('Failed to delete audio file:', error);
          // You might want to throw this error or handle it differently
        }
      } 

      // Delete the audio file from URL if it exists
      if (recording?.file?.audioURL) {
        
        await deleteFile(recording.file.audioURL);
        
      } 

      // Remove the recording from the database
      
      await RecordingManager.removeRecording(recordingKey);
      

      // Update the local store
      
      recordingStore.removeRecording(recordingKey);
      

      onClose?.();
      
    } catch (error) {
      console.error('Failed to delete recording:', error);
      alert('There was an error deleting the recording.');
    } finally {
      setIsUploading?.(false);
    }
  }
}
