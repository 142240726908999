// src/services/AudioService.ts
class AudioService {
    private audio: HTMLAudioElement | null = null;
  
    loadAudio(url: string): Promise<void> {
      return new Promise((resolve, reject) => {
        this.audio = new Audio(url);
        this.audio.oncanplaythrough = () => resolve();
        this.audio.onerror = (error) => reject(error);
      });
    }
  
    play(): void {
      this.audio?.play();
    }
  
    pause(): void {
      this.audio?.pause();
    }
  
    stop(): void {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    }
  
    getCurrentTime(): number {
      return this.audio?.currentTime || 0;
    }
  
    getDuration(): number {
      return this.audio?.duration || 0;
    }
  
    setCurrentTime(time: number): void {
      if (this.audio) {
        this.audio.currentTime = time;
      }
    }
  }
  
  export const audioService = new AudioService();