import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import {constructUrl } from "../utilities";
import { TagManager } from "../models";
import { IRecordingWithAuthor, IAuthor } from "../types";
import RecordingStore from '../stores/RecordingStore';
import QRCode from 'qrcode';
import RecordingForm from './RecordingForm';
import { handleDelete } from "../utilities/handleDelete";
import './EditRecording.css';
import authenticationStore from "../stores/AuthenticationStore";
import { AuthModal } from '../components';
import { observer } from 'mobx-react-lite';
import { projectStore } from '../stores/ProjectStore';
import L from 'leaflet';
import { saveRecording } from '../services/recordingService';
import { runInAction } from "mobx";

type RecordingEditorProps = {
  onClose: () => void;
  recordingKey?: string;
  recording?: IRecordingWithAuthor;
  pinKey?: string;
  pinType?: string;
  albumKey?: string | null;
  sourceView: 'PinList' | 'WanderView' | 'Form' | 'SideBar';
  onRecordingAdded?: () => void;
  onSave?: (updatedRecording: IRecordingWithAuthor) => Promise<void>;
  config: {
    locationInfo: boolean;
    authorInfo: boolean;
    recordingInfo: boolean;
    uploadFields: boolean;
  };
  project_id?: string;
  prefillAuthorData?: IAuthor | null;
  skipAuthCheck?: boolean;
};

type AuthorDetails = {
  authorName: string;
  authorBio: string;
  authorWebsite: string;
  authorTags: string[];
  authorImageUrl: string;
  userKey: string;
};

export const RecordingEditor: FunctionComponent<RecordingEditorProps> = observer(({
  onClose,
  recordingKey,
  recording,
  pinKey = '',
  pinType = '',
  albumKey = null,
  sourceView,
  onRecordingAdded,
  onSave,
  config,
  project_id,
  prefillAuthorData,
  skipAuthCheck = false,
}) => {
  const isEditing = !!recordingKey;

  const initialButtonState = (): 'mp3' | 'url' | 'record' => {
    if (recording?.file?.audioURL) {
      return 'url';
    } else if (recording?.file?.recordingPath) {
      return 'mp3';
    }
    return 'mp3'; // Default to 'mp3' if no existing data
  };

  const [selectedButton, setSelectedButton] = useState<'mp3' | 'url' | 'record'>(initialButtonState());
  const [authorDetails, setAuthorDetails] = useState<AuthorDetails>({
    authorName: recording?.author?.name || '',
    authorBio: recording?.author?.bio || '',
    authorWebsite: recording?.author?.website || '',
    authorTags: recording?.author?.tags?.authorTags || [],
    authorImageUrl: recording?.author?.image 
      ? constructUrl(recording.author.image)
      : '/assets/icons/user.svg',
    userKey: recording?.author?.userKey || '',
  });
  const [recordingDetails, setRecordingDetails] = useState({
    recordingURL: recording?.file?.audioURL || '',
    fileSize: recording?.file?.size,
    fileName: recording?.file?.recordingPath ? recording.file.recordingPath.split('/').pop() || undefined : undefined,
    transcription: recording?.transcription || '',
    title: recording?.file?.title || '',
    description: recording?.file?.description || '',
    narrator: recording?.ownership?.recordingNarrator || '',
    subjectTags: recording?.tags?.subjectTags || [],
    genreTags: recording?.tags?.genreTags || [],
    whereQRFind: recording?.whereQRFind,
    selectedButton: initialButtonState(),
  });
  const [availableGenreTags, setAvailableGenreTags] = useState<string[]>([]);
  const [initialAuthorTags, setInitialAuthorTags] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [locationDescription, setLocationDescription] = useState('');
  const [, setQrPath] = useState<string>(recording?.qrPath || '');
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [retainedAuthorDetails, setRetainedAuthorDetails] = useState<AuthorDetails | null>(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<L.LatLng | null>(null);
  const [error, setError] = useState<string | undefined>(undefined); // Change type to string | undefined
  const [initializedAuthorDetails, setInitializedAuthorDetails] = useState(false);

  const selectedProject = projectStore.projects.find(p => p.project_id === projectStore.selectedProject)
    || projectStore.projects[0]
    || { icon: '/public/assets/overhear-assets/images/ovh-logoartboard-12x-1.png' };


  const projectIconUrl = selectedProject?.icon
    ? constructUrl(selectedProject.icon)
    : '/public/assets/overhear-assets/images/ovh-logoartboard-12x-1.png';

  const logs: string[] = []; // Initialize an array to capture logs

  useEffect(() => {
    logs.push('Fetching projects...');
    if (projectStore.projects.length === 0) {
      projectStore.fetchProjects();
    }
  }, []);


  useEffect(() => {
    logs.push(`Recording changed: ${JSON.stringify(recording)}`);
    if (recording?.author) {
      setAuthorDetails(prevDetails => ({
        ...prevDetails,
        authorName: recording.author?.name || '',
        authorBio: recording.author?.bio || '',
        authorWebsite: recording.author?.website || '',
        authorTags: recording.author?.tags?.authorTags || [],
        authorImageUrl: recording.author?.image 
          ? constructUrl(recording.author.image)
          : '/assets/icons/user.svg',
        userKey: recording.author?.userKey || '',
      }));
      if (recording.author.tags?.authorTags) {
        const initialAuthorTags = Array.isArray(recording.author.tags.authorTags)
          ? recording.author.tags.authorTags
          : [];
        setInitialAuthorTags(initialAuthorTags);
      }
    }
    if (recording?.tags) {
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        subjectTags: recording.tags?.subjectTags || [],
        genreTags: recording.tags?.genreTags || [],
      }));
    }
    // Add this log to check the initial values
  }, [recording]);


  useEffect(() => {
    const fetchGenreTags = async () => {
      try {
        const adminTags = await TagManager.observeAdminTags();
        const fetchedGenreTags = adminTags.length > 0 ? adminTags[0].genreTags : [];
        setAvailableGenreTags(fetchedGenreTags);
      } catch (error) {
        console.error('Error fetching genre tags:', error);
      }
    };
    fetchGenreTags();
  }, []);

  useEffect(() => {
    if (recording && !initializedAuthorDetails) {
      const newAuthorDetails = {
        authorName: recording.author?.name || '',
        authorBio: recording.author?.bio || '',
        authorWebsite: recording.author?.website || '',
        authorTags: recording.author?.tags?.authorTags || [],
        authorImageUrl: recording.author?.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg',
        userKey: recording.author?.userKey || '',
      };
      setAuthorDetails(newAuthorDetails);
      setInitializedAuthorDetails(true);
    }
  }, [recording, initializedAuthorDetails]);

  const handleAuthorDetailsChange = useCallback((details: Partial<{
    authorName: string;
    authorBio: string;
    authorWebsite: string;
    authorTags: string[];
    imageFile?: File | null;
  }>) => {
    setAuthorDetails(prevDetails => {
      const newDetails = {
        ...prevDetails,
        ...details,
        authorImageUrl: details.imageFile ? URL.createObjectURL(details.imageFile) : prevDetails.authorImageUrl
      };
      return newDetails;
    });
    if (details.imageFile) {
      setSelectedImageFile(details.imageFile);
    }
  }, []);

  const handleRecordingDetailsChange = useCallback((details: Partial<typeof recordingDetails> & { file?: File, selectedButton?: 'mp3' | 'url' | 'record' }) => {
    setRecordingDetails(prevDetails => {
      const newDetails = {
        ...prevDetails,
        ...details,
        selectedButton: details.selectedButton || prevDetails.selectedButton,
        // Preserve the recordingPath if it's not explicitly changed
        fileName: details.fileName || prevDetails.fileName,
      };
      return newDetails;
    });

    if (details.file) {
      setSelectedFile(details.file);
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        fileName: details.file?.name
      }));
    }
  }, []);

  const handleLocationDescriptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLocationDescription(value);

    if (value.length >= 5) {  // Assuming a minimum length for a valid postcode or address
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}`);
        const data = await response.json();
        if (data && data.length > 0) {
          const { lat, lon } = data[0];
          // setMapVisible(true);
          // initializeMap(parseFloat(lat), parseFloat(lon));
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    }
  };

  const generateAndUploadQRCode = async (data: string): Promise<string> => {
    const qrCode = await QRCode.toDataURL(data, {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      renderer: {
        quality: 0.92
      }
    });
    return qrCode;
  };

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSave = async () => {
    if (!skipAuthCheck && !authenticationStore.isAuthenticated) {
      setShowAuthModal(true);
      return;
    }

    setIsUploading(true);
    setError(undefined);

    try {
      // Determine if transcription should be initiated
      const hasExistingTranscription = !!recording?.transcription;
      const hasExistingTimingData = !!recording?.timingData && Object.keys(recording.timingData).length > 0;
      const hasExistingTranscriptionResults = !!recording?.transcriptionResults && recording.transcriptionResults.length > 0;
      const isNewFile = !!(selectedFile && (!recording?.file?.size || selectedFile.size !== recording.file.size));

      console.log('Transcription initiation check:');
      console.log('- Has existing transcription:', hasExistingTranscription, 'Content:', recording?.transcription);
      console.log('- Has existing timing data:', hasExistingTimingData, 'Content:', JSON.stringify(recording?.timingData));
      console.log('- Has existing transcription results:', hasExistingTranscriptionResults, 'Content:', JSON.stringify(recording?.transcriptionResults));
      console.log('- Is new file:', isNewFile);

      const shouldInitiateTranscription = !!(
        (!hasExistingTranscription && !hasExistingTimingData && !hasExistingTranscriptionResults) || 
        isNewFile
      );

      console.log('Should initiate transcription:', shouldInitiateTranscription);

      const { updatedRecording, logs } = await saveRecording({
        recordingKey,
        authorDetails: {
          authorKey: recording?.author?.authorKey,
          name: authorDetails.authorName,
          bio: authorDetails.authorBio,
          website: authorDetails.authorWebsite,
          tags: authorDetails.authorTags,
          imageFile: selectedImageFile || undefined,
          existingImageUrl: selectedImageFile ? undefined : (recording?.author?.image || authorDetails.authorImageUrl),
        },
        recordingDetails: {
          ...recordingDetails,
          audioFile: selectedFile || undefined, 
        },
        projectId: selectedProject.project_id,
        pinKey,
        albumKey,
        currentUserKey: authenticationStore.user?.uid || undefined,
        sourceView,
        pinType,
        markerPosition: markerPosition ? { lat: markerPosition.lat, lng: markerPosition.lng } : undefined,
        locationDescription,
        initialAuthorTags,
        shouldInitiateTranscription 
      });

      console.log('Save operation logs:', logs);
      console.log('Updated recording after save:', updatedRecording);

      // Update local state
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        fileName: updatedRecording.file?.recordingPath || prevDetails.fileName,
        // ... update other fields as necessary
      }));

      setAuthorDetails(prevDetails => ({
        ...prevDetails,
        authorName: updatedRecording.author?.name || '',
        authorBio: updatedRecording.author?.bio || '',
        authorWebsite: updatedRecording.author?.website || '',
        authorTags: updatedRecording.author?.tags?.authorTags || [],
        authorImageUrl: updatedRecording.author?.image 
          ? constructUrl(updatedRecording.author.image)
          : '/assets/icons/user.svg',
        userKey: updatedRecording.author?.userKey || '',
      }));

      // Force a re-render in the parent component
      RecordingStore.setLastUpdated(new Date());

      // Call callbacks
      if (onRecordingAdded) {
        onRecordingAdded();
      }
      if (onSave && isEditing) {
        await onSave(updatedRecording);
      }

      setSaveSuccess(true);
      onClose();
    } catch (error) {
      console.error('Error in save process:', error);
      setError('An error occurred while saving the recording. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteRecording = async () => {
    if (isEditing && recordingKey && recording) {
      await handleDelete(
        recordingKey,
        pinKey,
        onClose,
        setIsUploading,
        recording,
        recordingDetails.fileSize
      );
    }
  };

  const resetForm = useCallback(() => {
    setSelectedButton('mp3');
    setRecordingDetails({
      recordingURL: '',
      fileSize: undefined,
      fileName: undefined,
      transcription: '',
      title: '',
      description: '',
      narrator: '',
      subjectTags: [],
      genreTags: [],
      whereQRFind: '',
      selectedButton: 'mp3',
    });
    setQrPath('');
    setLocationDescription('');
    if (!isEditing) {
      setAuthorDetails({
        authorName: '',
        authorBio: '',
        authorWebsite: '',
        authorTags: [],
        authorImageUrl: '/assets/icons/user.svg',
        userKey: '',
      });
    }
    if (retainedAuthorDetails) {
      setAuthorDetails(retainedAuthorDetails);
    }
  }, [isEditing, retainedAuthorDetails]);

  const handleRetainArtistDetails = useCallback((retain: boolean) => {
    if (retain) {
      setRetainedAuthorDetails(authorDetails);
    } else {
      setRetainedAuthorDetails(null);
    }
    setSaveSuccess(false);
  }, [authorDetails]);

  useEffect(() => {
    if (!saveSuccess) {
      resetForm();
    }
  }, [saveSuccess, resetForm]);

  const setAuthorTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setAuthorDetails(prevDetails => ({
      ...prevDetails,
      authorTags: typeof newTags === 'function' ? newTags(prevDetails.authorTags) : newTags
    }));
  }, []);

  const setSubjectTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setRecordingDetails(prevDetails => ({
      ...prevDetails,
      subjectTags: typeof newTags === 'function' ? newTags(prevDetails.subjectTags) : newTags
    }));
  }, []);

  const setGenreTags = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
    setRecordingDetails(prevDetails => ({
      ...prevDetails,
      genreTags: typeof newTags === 'function' ? newTags(prevDetails.genreTags) : newTags
    }));
  }, []);

  useEffect(() => {
    if (recording) {
      setAuthorDetails({
        authorName: recording.author?.name || '',
        authorBio: recording.author?.bio || '',
        authorWebsite: recording.author?.website || '',
        authorTags: recording.author?.tags?.authorTags || [],
        authorImageUrl: recording.author?.image ? constructUrl(recording.author.image) : '/assets/icons/user.svg',
        userKey: recording.author?.userKey || '',
      });
      setRecordingDetails(prevDetails => ({
        ...prevDetails,
        recordingURL: recording.file?.audioURL || '',
        fileSize: recording.file?.size,
        fileName: recording.file?.recordingPath,
        transcription: recording.transcription || '',
        title: recording.file?.title || '',
        description: recording.file?.description || '',
        narrator: recording.ownership?.recordingNarrator || '',
        subjectTags: recording.tags?.subjectTags || [],
        genreTags: recording.tags?.genreTags || [],
        whereQRFind: recording.whereQRFind || '',
        selectedButton: recording.file?.audioURL ? 'url' : 'mp3',
      }));
      setInitialAuthorTags(recording.author?.tags.authorTags || []);
    }
  }, [recording]);

  useEffect(() => {
    if (prefillAuthorData && sourceView === 'Form') {
      setAuthorDetails({
        authorName: prefillAuthorData.name || '',
        authorBio: prefillAuthorData.bio || '',
        authorWebsite: prefillAuthorData.website || '',
        authorTags: prefillAuthorData.tags?.authorTags || [],
        authorImageUrl: prefillAuthorData.image ? constructUrl(prefillAuthorData.image) : '/assets/icons/user.svg',
        userKey: prefillAuthorData.userKey || '',
      });
    }
  }, [prefillAuthorData, sourceView]);

  const handleMarkerPositionChange = useCallback((position: L.LatLng | null) => {
    setMarkerPosition(position);
  }, []);

  return (
    <div className={sourceView === 'WanderView' ? "edit-wander" : (sourceView === 'Form' ? "edit-recording form-view" : "edit-recording")}>
      <h1 className="edit-recording1">{isEditing ? 'Edit Recording' : 'Add Recording'}</h1>
      {error && <div className="error-message">{error}</div>} {/* Display error message */}
      <RecordingForm
        sourceView={sourceView}
        config={config}
        authorDetails={authorDetails}
        recordingDetails={{
          ...recordingDetails,
          selectedButton: selectedButton,
        }}
        locationDescription={locationDescription}
        pinType={pinType}
        isUploading={isUploading}
        onAuthorDetailsChange={handleAuthorDetailsChange}
        onRecordingDetailsChange={handleRecordingDetailsChange}
        onLocationDescriptionChange={handleLocationDescriptionChange}
        onCancel={handleCancel}
        onSave={handleSave}
        onDelete={handleDeleteRecording}
        showDelete={isEditing}
        saveSuccess={saveSuccess}
        setSaveSuccess={setSaveSuccess}
        resetForm={resetForm}
        setRetainArtistDetails={handleRetainArtistDetails}
        availableGenreTags={availableGenreTags}
        setAuthorTags={setAuthorTags}
        setSubjectTags={setSubjectTags}
        setGenreTags={setGenreTags}
        projectIconUrl={projectIconUrl}
        onMarkerPositionChange={handleMarkerPositionChange}
        error={error}
      />
      {!skipAuthCheck && showAuthModal && (
        <AuthModal
          onClose={() => setShowAuthModal(false)}
          onAuthSuccess={() => {
            setShowAuthModal(false);
            authenticationStore.setUser(authenticationStore.user);
            handleSave();
          }}
          authorName={authorDetails.authorName}
        />
      )}
    </div>
  );
});

export default RecordingEditor;
