import React, { useState } from 'react';
import { FormAuthor } from './FormAuthor';
import "./AuthModal.css"

interface AuthModalProps {
  onClose: () => void;
  onAuthSuccess: () => void;
  authorName: string;
}

export const AuthModal: React.FC<AuthModalProps> = ({ onClose, onAuthSuccess, authorName }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [accountExistsMessage, setAccountExistsMessage] = useState('');

  const handleAccountExists = () => {
    setIsLogin(true);
    setAccountExistsMessage('An account with this email already exists. Please log in.');
  };

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal">
        <button className="close-button" onClick={onClose}>×</button>
        <p>Sign Up or Log in to Submit your details.</p>
        {accountExistsMessage && <p className="error-message">{accountExistsMessage}</p>}
        <FormAuthor
          onAccountExists={handleAccountExists}
          onSuccess={onAuthSuccess}
          initialAuthorName={authorName}
          isModal={true}
          initialIsLogin={isLogin}
        />
      </div>
    </div>
  );
};

export default AuthModal;