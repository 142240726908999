import { FunctionComponent, useCallback, useState } from "react";
import {
  TextField,
} from "@mui/material";
import "./InputForms.css";
import AllDataManager from "../models/AllDataManager";
import { IWanderPin } from "../types";
import WanderStore from "../stores/WanderStore";

type EditWanderProps = {
  onClose: () => void;
  wanderKey: string;
  wanderData: IWanderPin;
  onSave: (updatedWander: IWanderPin) => Promise<void>;
};

const EditWander: FunctionComponent<EditWanderProps> = ({ onClose, wanderKey, wanderData, onSave }) => {
  const [name, setWanderName] = useState<string>(wanderData.name);
  const [wanderDescription, setWanderDescription] = useState<string>(wanderData.pinDescription);
  const [isSaving, setIsSaving] = useState(false);

  const onCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleWanderNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWanderName(event.target.value);
  };

  const handleDelete = async () => {
    // Confirm deletion with the user
    if (window.confirm('Are you sure you want to delete this wander?')) {
        try {
            // Attempt to remove the pin and associated data
            await AllDataManager.removePinAndAssociatedData(wanderData.pinKey);
        } catch (error) {
            // Check if the error is because the pin was not found
            if ((error as Error).message === 'Pin not found') {

            } else {
                // If the error is for another reason, log it and stop the deletion process
                console.error('Error removing pin and associated data:', error);
                return;
            }
        }
        try {
            // Proceed to delete the wander from the WanderStore
            await WanderStore.deleteWander(wanderKey);
            onClose(); // Close the edit form upon successful deletion
        } catch (error) {
            console.error('Error deleting wander:', error);
        }
    }
};

  const handleSave = async () => {
    setIsSaving(true);

    const updatedWander: IWanderPin = {
      ...wanderData,
      name: name,
      pinDescription: wanderDescription,
    };

    try {
      await onSave(updatedWander);
      setIsSaving(false);
      onClose();
    } catch (error) {
      console.error('Error updating wander:', error);
      setIsSaving(false);
    }
  };

  return (
    <div className="edit-wander">
      <h1 className="heading1">Edit Wander</h1>
      <TextField
        className="input-container"
        color="primary"
        label="Name"
        size="medium"
        variant="filled"
        type="text"
        value={name}
        onChange={handleWanderNameChange}
      />
      <button className="button-action-delete" onClick={handleDelete}>
        <b className="delete-text">Delete</b>
      </button>
      <div className="buttons">
        <button className="button-action-cancel" onClick={onCancelClick}>
          <b className="cancel-text">Cancel</b>
        </button>
        <button className="button-action-save" onClick={handleSave} disabled={isSaving}>
          <b className="save-text">{isSaving ? 'Saving...' : 'Save'}</b>
        </button>
      </div>
    </div>
  );
};

export default EditWander;