// stores/AllDataStore.ts
import { makeAutoObservable } from "mobx";
import { IPinWithRecordings } from "../types";
import { PinManager } from "../models/PinManager";

class AllDataStore {
  pins: IPinWithRecordings[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setPins(pins: IPinWithRecordings[]) {
    this.pins = pins;
  }


  // Add this method to the AllDataStore class
async togglePinAvailability(pinKey: string) {
  const pinIndex = this.pins.findIndex(pin => pin.pinKey === pinKey);
  if (pinIndex !== -1) {
    // Toggle the isAvailable property
    const pin = this.pins[pinIndex];
    pin.isAvailable = !pin.isAvailable;

    // Update the pin in the local state
    this.pins[pinIndex] = pin;

    // Update the pin in Firestore
    try {
      await PinManager.updatePin(pin);
    } catch (error) {
      console.error("Error toggling pin availability: ", error);
      throw error;
    }
  } else {
    console.error("Pin not found with key: ", pinKey);
  }
}
}



export default new AllDataStore();