import { useEffect } from "react";
import { useLocalObservable } from "mobx-react-lite";
import PinStore from "../stores/PinStore";
import RecordingStore from "../stores/RecordingStore";
import { IPin, IRecordingWithAuthor } from "../types";

export const usePinData = (pinKey: string) => {
  const localStore = useLocalObservable(() => ({
    get pinData() {
      return PinStore.getPin(pinKey);
    },
    set pinData(value: IPin | undefined) {
      if (value !== undefined) {
        PinStore.setPin(pinKey, value);
      }
    },
    get recordings() {
      return Array.from(RecordingStore.recordings.values()).filter((rec: IRecordingWithAuthor) => rec.pinKey === pinKey);
    },
    get loading() {
      return PinStore.loading;
    },
    get error() {
      return PinStore.error;
    },
    updateRecordings(updatedPin: IPin) {
      const updatedRecordings = this.recordings.map((rec: IRecordingWithAuthor) => 
        rec.pinKey === updatedPin.pinKey ? { ...rec, isAvailable: updatedPin.isAvailable } : rec
      );
      RecordingStore.setRecordings(updatedRecordings);
    },
    async updatePin(updatedPin: IPin) {
      await PinStore.updatePin(updatedPin);
      this.pinData = updatedPin;
      this.updateRecordings(updatedPin);
    },
  }));

  useEffect(() => {
    PinStore.fetchPin(pinKey);
    RecordingStore.fetchRecordingsForPin(pinKey);
  }, [pinKey]);

  useEffect(() => {
  }, [localStore.loading, localStore.error]);

  return localStore;
};