// src/models/AllDataManager.ts
import { IPinWithRecordings } from "../types/PinWithRecordings";
import { PinManager } from "./PinManager";
import { RecordingManager } from "./RecordingManager";
import { AuthorManager } from "./AuthorManager";
import AlbumManager from "./AlbumManager";
import { TagManager } from "./TagManager"; // Import TagManager
import { ProjectManager } from "./ProjectManager";

export class AllDataManager {
  async observeAll(): Promise<IPinWithRecordings[]> {
    try {
      // Fetch all pins, recordings, and authors
      const [pins, recordings, authors] = await Promise.all([
        PinManager.observePins(),
        RecordingManager.observeRecordings(),
        AuthorManager.getAllAuthors()
      ]);
      const pinsWithRecordings: IPinWithRecordings[] = pins.map(pin => {
        // Find the recordings for this pin
        const pinRecordings = recordings.filter(recording => recording.pinKey === pin.pinKey);

        // Map each recording to an IRecordingWithAuthor
        const recordingsWithAuthors = pinRecordings.map(recording => {
          // Find the author for this recording
          const author = authors.find(author => author.authorKey === recording.ownership?.recordingAuthor);

          // Return the recording with the author
          return { ...recording, author };
        });

        // Return the pin with the recordings
        return { ...pin, recordings: recordingsWithAuthors };
      });

      return pinsWithRecordings;
    } catch (error) {
      console.error("Error observing all data:", error);
      return [];
    }
  }

  async observeProjectData(project_id: string): Promise<IPinWithRecordings[]> {
    try {
      // Fetch all pins for the selected project
      const pins = await PinManager.getPinsByProjectKey(project_id);
      // Fetch all recordings for the selected project
      const recordings = await RecordingManager.getRecordingsByProjectKey(project_id);

      // Get unique author keys from the recordings
      const authorKeys = Array.from(new Set(recordings.map(recording => recording.ownership?.recordingAuthor).filter((key): key is string => key !== null && key !== undefined)));

      // Fetch authors that correspond to the recordingAuthors
      const authors = await Promise.all(authorKeys.map(authorKey => AuthorManager.getAuthor(authorKey)));

      // Map each pin to an IPinWithRecordings
      const pinsWithRecordings: IPinWithRecordings[] = pins.map(pin => {
        // Find the recordings for this pin
        const pinRecordings = recordings.filter(recording => recording.pinKey === pin.pinKey);

        // Map each recording to an IRecordingWithAuthor
        const recordingsWithAuthors = pinRecordings.map(recording => {
          // Find the author for this recording
          const author = authors.find(author => author?.authorKey === recording.ownership?.recordingAuthor);

          // Return the recording with the author
          return author ? { ...recording, author } : recording;
        });

        // Return the pin with the recordings
        return { ...pin, recordings: recordingsWithAuthors };
      });

      return pinsWithRecordings;
    } catch (error) {
      console.error("Error observing project data:", error);
      return [];
    }
  }

  async removePinAndAssociatedData(pinKey: string): Promise<void> {
    try {
      // Fetch the pin
      const pin = await PinManager.getPin(pinKey);
      if (!pin) throw new Error('Pin not found');
    
      // Fetch the associated album using the albumKey from the pin
      const albumKey = pin.albumKey;
      if (!albumKey) throw new Error('Album key not found in pin');
    
      // Fetch the associated recordings using getRecordingsForPin
      const recordings = await RecordingManager.getRecordingsForPin(pinKey);
    
      // Delete the recordings
      await Promise.all(recordings.map(recording => RecordingManager.removeRecording(recording.key)));
    
      // Delete the album
      await AlbumManager.removeAlbum(albumKey);
    
      // Delete the pin
      await PinManager.removePin(pinKey);

      // Remove pin from tags
      await TagManager.removePinFromTags(pinKey); // Added line: Call the new method in TagManager
    } catch (error) {
      console.error('Error removing pin and associated data:', error);
      throw error;
    }
  }

  async removeProjectAndAssociatedData(project_id: string): Promise<void> {
    try {
      // Fetch all pins for the project
      const pins = await PinManager.getPinsByProjectKey(project_id);

      // Delete all pins and their associated data
      for (const pin of pins) {
        await this.removePinAndAssociatedData(pin.pinKey);
      }

      // Here you would also handle the deletion of any other project-specific data
      // For example, if there are project-specific tags, recordings, etc.

      // Finally, delete the project itself
      await ProjectManager.deleteProject(project_id);

    } catch (error) {
      console.error('Error removing project and associated data:', error);
      throw error;
    }
  }
}

export default new AllDataManager();