import React, { useState, useEffect } from 'react';
import { Icon } from './Icon';
import Drawer from '@mui/material/Drawer';
import { IRecordingWithAuthor } from '../types';
import { RecordingManager } from '../models/RecordingManager';
import { AuthorManager } from '../models/AuthorManager';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import './Sidebar.css'
import { handleDelete } from '../utilities/handleDelete';
import PortalPopup from './PortalPopup';
import RecordingEditor from './RecordingEditor';
import RecordingStore from "../stores/RecordingStore";
import AllDataManager from "../models/AllDataManager"; // Import AllDataManager to fetch project details
import { ProjectManager } from "../models/ProjectManager"; // Import ProjectManager

interface SidebarProps {
  selectedProject: string;
  closeSidebar: () => void;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (open: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ selectedProject, isDrawerOpen, setIsDrawerOpen, closeSidebar }) => {

  const [orphanedRecordings, setOrphanedRecordings] = useState<IRecordingWithAuthor[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentRecording, setCurrentRecording] = useState<IRecordingWithAuthor | null>(null);

  const fetchOrphanedRecordingsWithAuthors = async () => {
    if (selectedProject) {
      const recordings = await RecordingManager.getOrphanedRecordingsForProject(selectedProject);
      const recordingsWithAuthors = await Promise.all(recordings.map(async (recording) => {
        if (recording.ownership?.recordingAuthor) {
          const author = await AuthorManager.getAuthor(recording.ownership.recordingAuthor);
          return { ...recording, author } as IRecordingWithAuthor;
        }
        return recording as IRecordingWithAuthor;
      }));
      setOrphanedRecordings(recordingsWithAuthors);
    }
  };

  useEffect(() => {
    fetchOrphanedRecordingsWithAuthors();
  }, [selectedProject]);

  const onSave = async (updatedRecording: IRecordingWithAuthor) => {
    try {
      await RecordingStore.updateRecording(updatedRecording.key, updatedRecording);
      // Refresh the orphaned recordings list after saving
      await fetchOrphanedRecordingsWithAuthors();
    } catch (error) {
      console.error('Error saving recording:', error);
    }
  };

  const handleMenuClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleMoreIconClick = (recording: IRecordingWithAuthor) => {
    setCurrentRecording(recording);
    setIsPopupOpen(true);
  };

  const handleDeleteRecording = async (recordingKey: string) => {
    try {
      await handleDelete(recordingKey);
      await fetchOrphanedRecordingsWithAuthors(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting recording:', error);
    }
  };

  if (orphanedRecordings.length === 0) {
    return null;
  }
  return (
    <div style={sidebarStyle}>
      <Icon icon="burger" size={28} onClick={handleMenuClick} style={iconStyle} />
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={closeSidebar}
        ModalProps={{
          // Disable the backdrop (the grey area outside the drawer)
          BackdropProps: {
            style: {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        <Droppable droppableId="sidebar">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={drawStyle}>
              <h3>Drag any unassigned recordings below on to existing Pins</h3>
              {orphanedRecordings.map((recording, index) => (
                <Draggable key={recording.key} draggableId={recording.key} index={index}  >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}

                    >
                      <div className='orphanedRecordings' >
                        <a className="list-item">{recording.file?.title}</a>
                        <div >
                          <Icon icon='more' size={20} style={{ marginRight: 10 }} onClick={() => handleMoreIconClick(recording)} />
                          <Icon icon='x' style={{ marginRight: 10 }} onClick={() => handleDeleteRecording(recording.key)} />
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Drawer>
      {isPopupOpen && currentRecording && (
        <PortalPopup
          onOutsideClick={() => setIsPopupOpen(false)}
          overlayColor="rgba(0, 0, 0, 0.5)"
        >
          <RecordingEditor
            onClose={() => setIsPopupOpen(false)}
            recordingKey={currentRecording.key}
            recording={currentRecording}
            onSave={onSave}
            sourceView="SideBar"
            config={{
              locationInfo: false,
              authorInfo: true,
              recordingInfo: true,
              uploadFields: true,
            }}
          />
        </PortalPopup>
      )}
    </div>
  );
};

const sidebarStyle: React.CSSProperties = {
  position: 'fixed',
  top: '500px',
  right: '0px',
  zIndex: 99,
  borderBottomLeftRadius: 30,
  borderTopLeftRadius: 30,
  width: 80,
  backgroundColor: 'blue',
};

const iconStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  marginTop: 20,
  marginBottom: 20,
  border: 0

};

const drawStyle: React.CSSProperties = {
  flexDirection: 'column',
  marginInline: 30
};


export default Sidebar;
