import React from 'react';
import './ListHeader.css'; // Import the new CSS file

interface ListHeaderProps {
  headers: string[];
  columnCount: number; // Add a prop for the number of columns
}

const ListHeader: React.FC<ListHeaderProps> = ({ headers, columnCount }) => {
  return (
    <div className="list-header" style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
      {headers.map((header, index) => (
        <a key={index} className="header-item">{header}</a>
      ))}
    </div>
  );
};

export default ListHeader;