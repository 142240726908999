import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAuthor } from '../types/Author';
import { AuthorManager, RecordingManager, UserManager } from '../models';
import { constructUrl } from '../utilities/constructUrl';
import './AuthorClaimProcess.css';

const AuthorClaimProcess: React.FC = () => {
  const [potentialAuthors, setPotentialAuthors] = useState<IAuthor[]>([]);
  const [selectedAuthors, setSelectedAuthors] = useState<string[]>([]);
  const [mergedBio, setMergedBio] = useState('');
  const [selectedWebsite, setSelectedWebsite] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [currentAuthor, setCurrentAuthor] = useState<IAuthor | null>(null);
  const [areBiosIdentical, setAreBiosIdentical] = useState(false);
  const [customWebsite, setCustomWebsite] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { name, userKey } = location.state as { name: string; userKey: string };

  useEffect(() => {
    const fetchData = async () => {
      const authors = await AuthorManager.getAuthorsByName(name);
      setPotentialAuthors(authors);

      const user = await UserManager.getUserByKey(userKey);
      if (user?.userProfile.authorKey) {
        const author = await AuthorManager.getAuthorByKey(user.userProfile.authorKey);
        if (author) {
          setCurrentAuthor(author);
          setMergedBio(author.bio || '');
          setSelectedWebsite(author.website || '');
          setSelectedImage(author.image || '');
        }
      }
    };
    fetchData();
  }, [name, userKey]);

  const handleAuthorSelection = (authorKey: string) => {
    setSelectedAuthors(prev => 
      prev.includes(authorKey) 
        ? prev.filter(key => key !== authorKey)
        : [...prev, authorKey]
    );
  };

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMergedBio(e.target.value);
  };

  const handleWebsiteSelection = (website: string) => {
    setSelectedWebsite(website);
    setCustomWebsite(''); // Clear custom website when selecting an existing one
  };

  const handleCustomWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomWebsite(e.target.value);
    setSelectedWebsite(''); // Clear selected website when entering a custom one
  };

  const handleImageSelection = (image: string) => {
    setSelectedImage(image);
  };

  const getSelectedAuthors = () => {
    return potentialAuthors.filter(author => author.authorKey && selectedAuthors.includes(author.authorKey));
  };

  const combineBios = () => {
    const selectedAuthorBios = getSelectedAuthors().map(author => author.bio).filter(Boolean);
    const biosToMerge = currentAuthor?.bio 
      ? [currentAuthor.bio, ...selectedAuthorBios] 
      : selectedAuthorBios;
    const uniqueBios = new Set(biosToMerge);
    
    if (uniqueBios.size === 1) {
      setAreBiosIdentical(true);
      setMergedBio(biosToMerge[0] || '');
    } else {
      setAreBiosIdentical(false);
      setMergedBio(biosToMerge.join('\n\n'));
    }
  };

  const shouldShowCombineOption = () => {
    if (currentAuthor?.bio) {
      // If there's a current bio, always show the option
      return true;
    } else {
      // If no current bio, only show if there are multiple selected authors
      return selectedAuthors.length > 1;
    }
  };

  const areBiosDifferent = () => {
    const selectedAuthorBios = getSelectedAuthors().map(author => author.bio).filter(Boolean);
    const allBios = currentAuthor?.bio ? [currentAuthor.bio, ...selectedAuthorBios] : selectedAuthorBios;
    return new Set(allBios).size > 1;
  };

  const handleClaimAuthors = async () => {
    // Fetch the current user's data to get the authorKey
    const currentUser = await UserManager.getUserByKey(userKey);
    let finalAuthorKey = currentUser?.userProfile.authorKey;

    if (!finalAuthorKey) {
      console.error('No authorKey found for the current user');
      return;
    }

    if (selectedAuthors.length > 0) {
      // Merge selected authors with user preferences
      const mergedAuthor = await AuthorManager.mergeAuthors(selectedAuthors, userKey, finalAuthorKey);
      
      // Update merged author with user preferences
      await AuthorManager.updateAuthor(finalAuthorKey, {
        ...mergedAuthor,
        bio: mergedBio || mergedAuthor.bio,
        website: selectedWebsite || mergedAuthor.website,
        image: selectedImage || mergedAuthor.image,
      });

      // Update recordings with new authorKey
      await RecordingManager.updateRecordingsAuthor(selectedAuthors, finalAuthorKey);
      
      // Delete old author entries
      for (const authorKey of selectedAuthors) {
        if (authorKey !== finalAuthorKey) {
          await AuthorManager.deleteAuthor(authorKey);
        }
      }
    } else {
      // Create new author entry if no existing authors were selected
      const newAuthorData: Omit<IAuthor, 'authorKey'> = {
        name,
        userKey,
        tags: { authorTags: [] },
        isClaimed: true,
        bio: mergedBio,
        website: selectedWebsite,
        image: selectedImage,
      };
      await AuthorManager.createAuthor(finalAuthorKey, newAuthorData);
    }

    // Navigate to author dashboard or profile page
    navigate('/author-dashboard', { state: { authorKey: finalAuthorKey } });
  };

  const selectedAuthorsWithWebsites = getSelectedAuthors().filter(author => author.website);
  const selectedAuthorsWithImages = getSelectedAuthors().filter(author => author.image);

  return (
    <div className="author-claim-process">
      <h1>Claim Existing Artist Profiles</h1>
      <h2>Is this you?</h2>
      <p>We found the following potential matches for your name. Please select any that represent you:</p>
      {potentialAuthors.map(author => (
        <div key={author.authorKey} className="author-option">
          <input
            type="checkbox"
            id={author.authorKey}
            checked={selectedAuthors.includes(author.authorKey || '')}
            onChange={() => author.authorKey && handleAuthorSelection(author.authorKey)}
          />
          <label htmlFor={author.authorKey}>
            {author.name} - {author.bio}
          </label>
        </div>
      ))}
      
      {selectedAuthors.length > 0 && (
        <div className="merge-options">
          <h3>Merge Options</h3>
          {currentAuthor && (
            <div className="current-author-info">
              <h4>Your Current Author Information:</h4>
              <p><strong>Bio:</strong> {currentAuthor.bio || 'No bio available'}</p>
              <p><strong>Website:</strong> {currentAuthor.website || 'No website available'}</p>
              {currentAuthor.image && (
                <div>
                  <strong>Current Image:</strong>
                  <img src={constructUrl(currentAuthor.image)} alt="Current author" style={{width: '60px', height: '60px', marginLeft: 20}} />
                </div>
              )}
            </div>
          )}
          <div>
            <h4>Bio</h4>
            <textarea 
              value={mergedBio} 
              onChange={handleBioChange}
              placeholder="Enter or edit combined bio here"
              style={{maxWidth: '100%', width: '100%', minWidth:'100%', height: 200 }}
            />
            {shouldShowCombineOption() && areBiosDifferent() && (
              <button onClick={combineBios}>
                {currentAuthor?.bio
                  ? selectedAuthors.length > 0
                    ? "Combine Current and Selected Bios"
                    : "Use Current Bio"
                  : "Combine Selected Bios"}
              </button>
            )}
            {!areBiosDifferent() && <p>Note: All bios are identical.</p>}
          </div>
          
          <div>
            <h4>Website</h4>
            {selectedAuthorsWithWebsites.map(author => (
              <div key={author.authorKey} className="radio-option">
                <input 
                  type="radio" 
                  id={`website-${author.authorKey}`}
                  name="website"
                  value={author.website || ''}
                  checked={selectedWebsite === author.website}
                  onChange={() => handleWebsiteSelection(author.website || '')}
                />
                <label htmlFor={`website-${author.authorKey}`}>{author.website}</label>
              </div>
            ))}
            <div className="radio-option">
              <input 
                type="radio" 
                id="custom-website"
                name="website"
                checked={!!customWebsite}
                onChange={() => setCustomWebsite(selectedWebsite || '')}
              />
              <label htmlFor="custom-website">Custom website:</label>
            </div>
            <div className="custom-website-input">
              <input 
                type="text"
                value={customWebsite}
                onChange={handleCustomWebsiteChange}
                placeholder="Enter custom website"
              />
            </div>
          </div>
          
          {selectedAuthors.length > 1 && (
            <>
              {selectedAuthorsWithImages.length > 0 && (
                <div>
                  <h4>Image</h4>
                  {selectedAuthorsWithImages.map(author => (
                    <div key={author.authorKey} className="radio-option">
                      <input 
                        type="radio" 
                        id={`image-${author.authorKey}`}
                        name="image"
                        value={author.image || ''}
                        checked={selectedImage === author.image}
                        onChange={() => handleImageSelection(author.image || '')}
                      />
                      <label htmlFor={`image-${author.authorKey}`}>
                        <img src={constructUrl(author.image || '')} alt={`${author.name}'s profile`} />
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      )}

      <button onClick={handleClaimAuthors}>Confirm & Save Selection</button>
    </div>
  );
};

export default AuthorClaimProcess;