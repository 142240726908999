// Import necessary dependencies
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

// Base URL for Firebase storage
const FIREBASE_STORAGE_BASE_URL = `https://firebasestorage.googleapis.com/v0/b/${FIREBASE_STORAGE_BUCKET}/o/`;

/**
 * Constructs a full URL for a file stored in Firebase storage.
 * @param {string} filePath - The path of the file in Firebase storage.
 * @param {boolean} includeMediaParam - Whether to include the media query parameter.
 * @returns {string} The full URL of the file.
 */
export function constructUrl(filePath: string, includeMediaParam: boolean = true): string {
  return `${FIREBASE_STORAGE_BASE_URL}${encodeURIComponent(filePath)}${includeMediaParam ? '?alt=media' : ''}`;
}