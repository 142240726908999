// src/hooks/useAdminNav.ts
import { useState, useEffect, useCallback } from 'react';
import { useProjectContext } from '../contexts/ProjectContext';
import { useLocation } from 'react-router-dom';
import { PinManager, ProjectManager } from '../models';
import { IProject } from '../types';
import pinStore from '../stores/PinStore';
import userStore from '../stores/UserStore';

export const useAdminNav = () => {
  const { selectedProject, setSelectedProject } = useProjectContext();
  const location = useLocation();
  const [projectName, setProjectName] = useState<string>('');
  const [projects, setProjects] = useState<IProject[]>([]);

  const userRole = userStore.userRole;

  // Fetch project name based on selectedProject
  useEffect(() => {
    const fetchProjectName = async () => {
      if (selectedProject) {
        const project = await ProjectManager.getProject(selectedProject);
        if (project) {
          setProjectName(project.projectName || '');
        }
      }
    };

    fetchProjectName();
  }, [selectedProject]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsList = await ProjectManager.observeProjects(); // Replace with actual API call if needed
        setProjects(projectsList);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []); 

  // Handle project change
  const onProjectChange = useCallback((projectKey: string) => {
    // Clear existing pins from the PinStore
    pinStore.clearPins();
  
    // Fetch new pins for the selected project
    const fetchPinsForProject = async () => {
      try {
        const pins = await PinManager.getPinsByProjectKey(projectKey);
        pinStore.setPins(pins);
      } catch (error) {
        console.error('Error fetching pins for project:', error);
      }
    };
  
    if (projectKey) {
      fetchPinsForProject();
    }
  
    // Update the selected project in the ProjectStore
    setSelectedProject(projectKey);
  }, [setSelectedProject]);



  return {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole,
    // ... any other shared state or functions
  };
};