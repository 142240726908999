// ProjectStore.ts
import { makeAutoObservable, runInAction } from 'mobx';
import { IProject } from "../types/Project"; // Import your IProject interface
import { ProjectManager } from '../models';

class ProjectStore {
  selectedProject: string = localStorage.getItem('selectedProject') || '';
  projects: IProject[] = []; // Assuming you have a list of projects

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedProject(project: string) {
    this.selectedProject = project;
    localStorage.setItem('selectedProject', project);
  }

  getSelectedProject() {
    return this.selectedProject;
  }

  addProject(newProject: IProject) {
    this.projects.push(newProject);
  }


  async fetchProjects() {
    try {
      const projectData = await ProjectManager.observeProjects();
      runInAction(() => {
        this.projects = projectData;
        if (!this.selectedProject && this.projects.length > 0) {
          this.setSelectedProject(this.projects[0].project_id);
        }
      });
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  }

  deleteProject = (project_id: string) => {
    this.projects = this.projects.filter(project => project.project_id !== project_id);
  }

  async updateProject(project_id: string, updatedProject: IProject) {
    try {
      // Find the index of the project to update
      const index = this.projects.findIndex(project => project.project_id === project_id);
      if (index !== -1) {
        // Update the project
        this.projects[index] = updatedProject;
      } else {
        throw new Error('Project not found');
      }
    } catch (error) {
      console.error('Error updating project:', error);
    }
  }

  async toggleProjectAvailability(project_id: string) {
    try {
      // Find the project in the local state
      const project = this.projects.find(p => p.project_id === project_id);
      if (!project) {
        throw new Error('Project not found');
      }
      // Toggle the isAvailable property
      const updatedIsAvailable = !project.isAvailable;
      // Update the project in Firestore
      await ProjectManager.updateProjectAvailability(project_id, updatedIsAvailable);
      // Update the project in the local state within an action
      runInAction(() => {
        project.isAvailable = updatedIsAvailable;
      });
    } catch (error) {
      console.error('Error toggling project availability:', error);
    }
  }
}



export const projectStore = new ProjectStore();
