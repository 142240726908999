// PinAccordion.tsx
import React, { FunctionComponent, useState, useCallback, useEffect } from "react";
import { Button, IconButton, CircularProgress } from "@mui/material";
import PortalPopup from "../PortalPopup";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import RecordingEditor from "../RecordingEditor";
import { observer } from "mobx-react-lite";
import "./Accordion.css";
import { Icon, PinEditor, RecordingAccordion } from "..";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { usePinData } from "../../hooks/usePinData";
import RecordingStore from "../../stores/RecordingStore";
import { constructUrl } from "../../utilities";
import { PinManager } from "../../models";
import pinStore from "../../stores/PinStore";
import { IPin, IRecordingWithAuthor } from "../../types";
import useMediaQuery from '@mui/material/useMediaQuery';

interface PinAccordionProps {
  pinKey: string;
  dragHandleProps?: any;
  isSequentialEnabled?: boolean;
  isDragOver: boolean;
}

export const PinAccordion: FunctionComponent<PinAccordionProps> = observer(({ pinKey, dragHandleProps, isSequentialEnabled, isDragOver }) => {
  const { pinData, updatePin, recordings, loading, error } = usePinData(pinKey);
  const [isEditPinOpen, setEditPinOpen] = useState(false);
  const [isRecordingEditorOpen, setRecordingEditorOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [totalDownloads, setTotalDownloads] = useState(0);
  const [config] = useState({
    locationInfo: true,
    authorInfo: true,
    recordingInfo: true,
    uploadFields: true,
  });

  const openRecordingEditor = useCallback(() => { 
    setRecordingEditorOpen(true);
  }, []);

  const closeRecordingEditor = useCallback(() => { 
    setRecordingEditorOpen(false);
  }, []);

  const openEditPin = useCallback(() => {
    setEditPinOpen(true);
  }, []);

  const closeEditPin = useCallback(() => {
    setEditPinOpen(false);
  }, []);

  const toggleAccordion = async () => {
    setIsOpen(prevOpen => !prevOpen);

    if (!isOpen && recordings.length === 0) {
      try {
        // Use RecordingStore to fetch recordings for the pin
        await RecordingStore.fetchRecordingsForPin(pinKey);
        // No need to call setRecordings because RecordingStore will automatically update the recordings state
      } catch (error) {
        console.error("Failed to fetch recordings: ", error);
      }
    }
  };

  const calculateFontSize = (text: string) => {
    const baseSize = isExtraSmallScreen ? 14 : isSmallScreen ? 16 : 20;
    const maxLength = isExtraSmallScreen ? 20 : isSmallScreen ? 25 : 30;
    if (text.length > maxLength) {
      const scaleFactor = maxLength / text.length;
      return Math.max(baseSize * scaleFactor, 12);
    }
    return baseSize;
  };

  const isSmallScreen = useMediaQuery('(max-width:960px)');
  const isExtraSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchTotalDownloads = async () => {
      if (pinData?.albumKey) {
        const downloads = await PinManager.getTotalDownloadsByAlbumKey(pinData.albumKey);
        setTotalDownloads(downloads);
      }
    };

    fetchTotalDownloads();
  }, [pinData?.albumKey]);

  const imageUrl = pinData?.pinIcon ? constructUrl(pinData?.pinIcon) : "/default-project-icon.png";

  const handleToggleAvailability = async () => {
    if (pinData) {
      try {
        // Use PinStore to toggle pin availability
        await pinStore.togglePinAvailability(pinData.pinKey);
      } catch (error) {
        console.error("Failed to toggle pin availability: ", error);
      }
    }
  };

  const handleSavePin = async (updatedPin: IPin) => {
    try {
      await updatePin(updatedPin);
      setEditPinOpen(false);
    } catch (error) {
      console.error("Failed to update pin: ", error);
    }
  };

  return (
    <div className={`accordion ${isDragOver ? 'drag-over' : ''} ${isSmallScreen ? 'small-screen' : ''} ${isExtraSmallScreen ? 'extra-small-screen' : ''}`} {...dragHandleProps}>
      <div className="accordion-header">
        <div className="drag-container">
          {isSequentialEnabled && <Icon icon="burger" size={24} />}
        </div>
        {!isExtraSmallScreen && <img className="icon" alt={pinData?.pinIcon} src={imageUrl} />}
        {pinData?.isAvailable ? (
          <CheckBoxIcon style={{ color: 'white', fontSize: 24 }} className="check-icon" onClick={handleToggleAvailability} />
        ) : (
          <CheckBoxOutlineBlankIcon style={{ color: 'white', fontSize: 24 }} className="check-icon" onClick={handleToggleAvailability} />
        )}
        <a className="headingdata" style={{ fontSize: `${calculateFontSize(pinData?.name || '')}px` }}>
          {pinData?.name}
        </a>
        {isSequentialEnabled && <a className="headingdata">{pinData?.sequentialOrderNum}</a>}
        <a className="headingdata">{totalDownloads}</a>
        <a className="headingdata">{pinData?.pinType}</a>
        <div className="chevron-container" onClick={toggleAccordion}>
          <Icon
            icon="chevron"
            width={60} // Set the desired width
            height={20}
            className={`chevron-stroke-icon ${isOpen ? 'chevron-rotated' : ''}`}
          />
        </div>
        <IconButton className="edit-container" color="primary" onClick={openEditPin}>
          <EditSharpIcon style={{ fontSize: 40, color: 'black' }} />
        </IconButton>
      </div>
      {isOpen && (
        <div className="pin-information">
          <div className="top-row">
            {pinData?.pinType === 'qrCode' && (
              <div className="content-container">
                <a className="pinlabel">{`Description: `}</a>
                <a className="pindata">{pinData?.pinDescription}</a>
              </div>
            )}
            <div className="content-container">
              <a className="pinlabel">Lat: {pinData?.location?.geoPoint?.lat}</a>
              <a className="pinlabel">Long: {pinData?.location?.geoPoint?.long}</a>
            </div>
          </div>
          <div className="next-row-details">
            <div className="content-container">
              <div className="tags-label">Location Tags:</div>
              {pinData?.tags?.locationTags.map((tag: string) => (
                <div className="tag" key={tag}>{tag}</div>
              ))}
            </div>
            {pinData?.isDeliverPinsSequentially === true && (
              <div className="content-container">
                <a className="pinlabel">Sequence :</a>
                <a className="pindata">{pinData?.sequentialOrderNum}</a>
              </div>
            )}
          </div>
          <h3 className="recordings-title">Recordings</h3>
          <div className="recordings-container"> 
          {loading ? (
            <div className="loading-container">
              <CircularProgress className="loading-container" />
            </div>
          ) : pinData?.pinType === 'transit' || pinData?.pinType === 'QRTransit' ? (
            <div className="no-recordings"></div>
          ) : recordings.length === 0 ? (
            <div className="no-recordings">No recordings have been added yet.</div>
          ) : (
            recordings.map((recording: IRecordingWithAuthor, index: number) => (
              <RecordingAccordion
                key={recording.key}
                recordingKey={recording.key}
                pinKey={pinKey}
                pinData={pinData}
                pinType={pinData?.pinType ?? undefined}
                className={`${index === 0 ? 'first-recording' : ''} ${index === recordings.length - 1 ? 'last-recording' : ''}`}
                sourceView="PinList"
              />
            ))
          )}
          {pinData?.pinType !== 'transit' && pinData?.pinType !== 'QRTransit' && (
            <div className="button-container5">
              <Button
                className="button-add-new-recording"
                color="primary"
                size="medium"
                variant="contained"
                onClick={openRecordingEditor}
              >
                Add Recording
              </Button>
            </div>
          )}
        </div>
</div>
      )}
      {isRecordingEditorOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeRecordingEditor}
        >
          <RecordingEditor
            onClose={closeRecordingEditor}
            recordingKey={undefined}
            recording={undefined}
            pinKey={pinKey}
            pinType={pinData?.pinType ?? undefined}
            albumKey={pinData?.albumKey}
            sourceView="PinList"
            onRecordingAdded={() => {
              RecordingStore.fetchRecordingsForPin(pinKey);
            }}
            config={config}
          />
        </PortalPopup>
      )}
      {isEditPinOpen && pinData && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closeEditPin}
        >
          <PinEditor
            onClose={closeEditPin}
            onSave={handleSavePin}
            pinData={pinData}
          />
        </PortalPopup>
      )}
    </div>

  );
});

export default PinAccordion;
