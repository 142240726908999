import { db } from '../firebase/firebase';
import { collection, getDocs, doc, updateDoc, getDoc, where, query, setDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { IPin } from '../types/Pin';
import { IRecording } from '../types/Recording';


export const PinManager = {
  pinIconCache: new Map<string, string>(),

  observePins: async (): Promise<IPin[]> => {
    try {
      const pinsRef = collection(db, 'Pins');
      const querySnapshot = await getDocs(pinsRef);
      const pins: IPin[] = querySnapshot.docs.map(doc => doc.data() as IPin);
      return pins;
    } catch (error) {
      console.error("Error fetching pins: ", error);
      return [];
    }
  },


  updatePinLocation: async (pinKey: string, lat: number, lng: number): Promise<void> => {
    if (!pinKey) {
      console.error('pinKey is undefined.');
      return;
    }
    const pinRef = doc(db, 'Pins', pinKey);
    try {
      await updateDoc(pinRef, {
        'location.geoPoint.lat': lat, // Update the latitude
        'location.geoPoint.long': lng // Update the longitude
      });
    } catch (error) {
      console.error("Error updating pin location: ", error);
      throw error;
    }
  },
  getPin: async (pinKey: string): Promise<IPin | null> => {
    try {
      const pinRef = doc(db, 'Pins', pinKey);
      const pinDoc = await getDoc(pinRef);
      return pinDoc.exists() ? (pinDoc.data() as IPin) : null;
    } catch (error) {
      console.error("Error fetching pin: ", error);
      return null;
    }
  },

  removePin: async (pinKey: string): Promise<void> => {
    try {
      const pinDocRef = doc(db, 'Pins', pinKey);
      await deleteDoc(pinDocRef);
    } catch (error) {
      console.error("Error removing pin:", error);
      throw error;
    }
  },

  getPinsByProjectKey: async (project: string): Promise<IPin[]> => {
    try {
      const pinsRef = collection(db, 'Pins');
      const q = query(pinsRef, where('project', '==', project));
      const querySnapshot = await getDocs(q);
      const pins: IPin[] = querySnapshot.docs.map(doc => doc.data() as IPin);
      return pins;
    } catch (error) {
      console.error("Error fetching pins by project key: ", error);
      return [];
    }
  },

  createPin: async (newPin: IPin): Promise<void> => {
    try {
      const pinRef = doc(db, 'Pins', newPin.pinKey); // Create a reference with the pinKey as the document ID
      await setDoc(pinRef, newPin); // Use setDoc to set the data for the new document
    } catch (error) {
      console.error("Error creating new pin: ", error);
      throw error;
    }
  },

  updatePin: async (updatedPin: IPin): Promise<void> => {
    if (!updatedPin.pinKey) {
      console.error('pinKey is undefined.');
      return;
    }
    const pinRef = doc(db, 'Pins', updatedPin.pinKey);
    try {
      // Here you would spread the updatedPin object to update all fields
      await updateDoc(pinRef, {
        ...updatedPin,

      });
    } catch (error) {
      console.error("Error updating pin: ", error);
      throw error;
    }
  },

  updateFirestoreWithNewOrder: async (pins: IPin[]): Promise<void> => {
    const batch = writeBatch(db);

    pins.forEach((pin, index) => {
      const pinRef = doc(db, 'Pins', pin.pinKey);
      batch.update(pinRef, { sequentialOrderNum: index + 1 });
    });

    await batch.commit();
  },
  getTotalDownloadsByAlbumKey: async (albumKey: string): Promise<number> => {
    try {
      const querySnapshot = await getDocs(query(collection(db, 'Recordings'), where('albumKey', '==', albumKey)));
      let totalDownloads = 0;
      querySnapshot.forEach((docSnapshot) => {
        const recording = docSnapshot.data() as IRecording;
        totalDownloads += recording.collectionLog?.timesCollected || 0;
      });
      return totalDownloads;
    } catch (error) {
      console.error("Error fetching total downloads by albumKey:", error);
      return 0;
    }
  },
};
