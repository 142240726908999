import { FunctionComponent, useMemo, CSSProperties, useEffect } from "react";
import { Button, Select, MenuItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { signOutUser } from '../services/authService';
import { IProject } from "../types";
import "./AdminNav.css";
import { useAdminNav } from "../hooks/useAdminNav";

type AdminNavType = {
  imageDimensions?: string;
  projectName?: string;
  children?: React.ReactNode;
  userRole?: string;
  projects?: IProject[];
  selectedProject?: string;
  showProjectDropdown?: boolean;
  onProjectChange?: (project: string) => void;

  /** Style props */
  projectAdminHeight?: CSSProperties["height"];
  projectAdminPosition?: CSSProperties["position"];
  projectAdminTop?: CSSProperties["top"];
  projectAdminLeft?: CSSProperties["left"];
  projectAdminNavPadding?: CSSProperties["padding"];


};

export const AdminNav: FunctionComponent<AdminNavType> = ({
  imageDimensions,
  projectAdminHeight,
  projectAdminPosition,
  projectAdminTop,
  projectAdminLeft,
  projectAdminNavPadding,
  showProjectDropdown = true,
}) => {
  const navigate = useNavigate();

  const {
    projectName,
    projects,
    onProjectChange,
    selectedProject,
    userRole,
  } = useAdminNav();



  const handleSignOut = async () => {
    try {
      await signOutUser();
      localStorage.removeItem('selectedProject');
      navigate('/loginscreen');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const onTagsClick = () => {
    navigate('/tags-view');
  };

  const onPinsClick = () => {
    navigate('/pin-list-view');
  };

  const onProjectsClick = () => {
    navigate('/project-view');
  };

  const onFormsClick = () => {
    navigate('/form-builder-view');
  };


  const adminNavStyle: CSSProperties = useMemo(() => {
    return {
      height: projectAdminHeight,
      position: projectAdminPosition,
      top: projectAdminTop,
      left: projectAdminLeft,
      padding: projectAdminNavPadding,
    };
  }, [
    projectAdminHeight,
    projectAdminPosition,
    projectAdminTop,
    projectAdminLeft,
    projectAdminNavPadding,
  ]);

  return (
    <div className="project-admin-nav" style={adminNavStyle}>
      <img className="icon-container" alt="" src={imageDimensions} />
      {userRole === 'superAdmin' && projects.length > 0 && showProjectDropdown && (
        <div>
          <Select
            value={selectedProject}
            onChange={(event) => onProjectChange(event.target.value as string)}
            placeholder="Select a Project"
            className="dropdown-container"
          >
            {projects.map((project) => (
              <MenuItem key={project.project_id} value={project.project_id}>
                {project.projectName}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      {userRole !== 'superAdmin' && <h1 className="project-name">{projectName}</h1>}
      {userRole === 'superAdmin' && (
        <>
          <Button
            className="menu-item"
            color="primary"
            size="medium"
            variant="text"
            onClick={onProjectsClick}
          >
            Super Admin
          </Button>
        </>
      )}
      <Button
        className="menu-item"
        color="primary"
        size="medium"
        variant="text"
        onClick={onPinsClick}
      >
        Pins
      </Button>
      <Button
        className="menu-item"
        color="primary"
        size="medium"
        variant="text"
        onClick={onTagsClick}
      >
        Tags
      </Button>
      <Button
        className="menu-item"
        color="primary"
        size="medium"
        variant="text"
        onClick={onFormsClick}
      >
        Forms
      </Button>
      <Button
        className="menu-item"
        color="secondary"
        size="medium"
        variant="text"
        onClick={handleSignOut}
      >
        Sign Out
      </Button>
    </div>
  );
};

export default AdminNav;
