import React, { FunctionComponent, useState } from "react";
import { observer } from "mobx-react-lite";
import UserManager from "../../models/UserManager";
import { User, FeatureFlags } from "../../types";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import "./Accordion.css";

interface UserAccordionProps {
    user: User;
}

export const UserAccordion: FunctionComponent<UserAccordionProps> = observer(({ user }) => {
    const [localFeatureFlags, setLocalFeatureFlags] = useState<FeatureFlags>({
        enableInAppRecording: user.featureFlags?.enableInAppRecording ?? false,
        enableWander: user.featureFlags?.enableWander ?? false,
    });

    const toggleFeatureFlag = async (flag: keyof FeatureFlags) => {
        if (!user.userKey) return; // Ensure userKey is defined

        const updatedFlags: FeatureFlags = {
            ...localFeatureFlags,
            [flag]: !localFeatureFlags[flag]
        };

        setLocalFeatureFlags(updatedFlags); // Update local state for immediate feedback

        try {
            // Ensure the update method can handle featureFlags directly
            await UserManager.updateUserFeatureFlags(user.userKey, updatedFlags);
        } catch (error) {
            console.error("Error updating feature flag:", error);
        }
    };

    return (
        <div className="accordion">
            <div className="user-accordion-header">
                <span className="headingdata">{user.userProfile.userName}</span>
                <span className="userKeydata">{user.userKey}</span>
                <span className="checkbox-container">
                    {localFeatureFlags.enableInAppRecording ? (
                        <CheckBoxIcon
                            style={{ color: 'white', fontSize: 24 }}
                            onClick={() => toggleFeatureFlag('enableInAppRecording')}
                        />
                    ) : (
                        <CheckBoxOutlineBlankIcon
                            style={{ color: 'white', fontSize: 24 }}
                            onClick={() => toggleFeatureFlag('enableInAppRecording')}
                        />
                    )}
                </span>
                <span className="checkbox-container">
                    {localFeatureFlags.enableWander ? (
                        <CheckBoxIcon
                            style={{ color: 'white', fontSize: 24 }}
                            onClick={() => toggleFeatureFlag('enableWander')}
                        />
                    ) : (
                        <CheckBoxOutlineBlankIcon
                            style={{ color: 'white', fontSize: 24 }}
                            onClick={() => toggleFeatureFlag('enableWander')}
                        />
                    )}
                </span>
            </div>
        </div>
    );
});

export default UserAccordion;