import { ProjectContext } from '../contexts/ProjectContext';

/**
 * Sends an email notification with form submission details.
 * 
 * This function sends an email to a predefined address with information about
 * a form submission, including the location description and any additional emailBody.
 * 
 * @param {string} locationDesc - The description of the location from the submitted form.
 * @param {string} emailBody - emailBody or additional information to include in the email.
 * @returns {Promise<void>} A promise that resolves when the email is sent successfully, or rejects on failure.
 * @throws {Error} Throws an error if the email sending fails.
 */
export const sendEmailNotification = async (locationDesc: string, emailBody: string): Promise<void> => {
  const response = await fetch('https://email-server-dot-overhear-2.uc.r.appspot.com/api/send-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: 'hello@theoverhear.app',
      subject: 'FormsView Submitted',
      text: `The form has been submitted with the following details:
      - Location Description: ${locationDesc}
      - emailBody: ${emailBody}`  // Include emailBody in the email body
    }),
  });

  if (!response.ok) {
    console.error('Failed to send email');
    throw new Error('Failed to send email notification');
  }
};