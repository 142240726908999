import { useEffect } from "react";
import { useLocalObservable } from "mobx-react-lite";
import AdminMessageStore from "../stores/AdminMessageStore";
import { IAdminMessage } from "../types/AdminMessage";

export const useAdminMessageData = (messageKey: string) => {
  const localStore = useLocalObservable(() => ({
    get adminMessage() {
      return AdminMessageStore.adminMessages.find(msg => msg.pinKey === messageKey);
    },
    set adminMessage(value: IAdminMessage | undefined) {
      if (value !== undefined) {
        const index = AdminMessageStore.adminMessages.findIndex(msg => msg.pinKey === messageKey);
        if (index !== -1) {
          AdminMessageStore.adminMessages[index] = value;
        } else {
          AdminMessageStore.addMessage(value);
        }
      }
    },
    get loading() {
      return AdminMessageStore.isLoading;
    },
    get error() {
      return AdminMessageStore.error;
    },
    async updateAdminMessage(updatedMessage: IAdminMessage) {
      // Implement the logic to update the admin message in the store and possibly in the backend
      // This is a placeholder for the actual update logic
      this.adminMessage = updatedMessage;
    },
  }));

  useEffect(() => {
    // Fetch the admin message data for the given messageKey
    AdminMessageStore.loadAdminMessages();
  }, [messageKey]);

  return localStore;
};