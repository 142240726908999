import { db } from '../firebase/firebase'; 
import { doc, getDoc, setDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { User } from '../types';
import { collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'; // Use firebase-admin for server-side operations
import { FeatureFlags } from '../types';

export const UserManager = {
  /**
   * Fetches the current user's data from Firestore.
   * The userId should be obtained from the AuthenticationStore after successful authentication.
   * @param userId The UID of the user, as obtained from Firebase Authentication.
   * @returns A Promise that resolves to the User data or null if not found.
   */
  getCurrentUser: async (userId: string): Promise<User | null> => {
    try {
      const userDocRef = doc(db, 'Users', userId);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        
        return null;
      }
  
      const userData = userDoc.data();
  
      return { userKey: userDoc.id, ...userData } as User;
    } catch (error) {
      console.error("Error fetching user:", error);
      return null;
    }
  },

  /**
   * Creates a new user document in Firestore.
   * @param userData The user data to be stored in Firestore.
   * @param username The username for the new user.
   * @returns A Promise that resolves to void if successful, or rejects with an error.
   */
  createUser: async (userData: Omit<User, 'userKey'> & { userKey: string }, username?: string): Promise<void> => {
    try {
      const { userKey, userProfile, ...restUserData } = userData;
      const userDocRef = doc(db, 'Users', userKey);
      
      await setDoc(userDocRef, {
        ...restUserData,
        userKey,
        userProfile: {
          userName: username ? username.trim() : userProfile.userName, // Use provided username or fallback to userProfile's userName
          bio: userProfile.bio,
          profileImage: userProfile.profileImage,
          restrictAutoAdd: userProfile.restrictAutoAdd,
          authorKey: userProfile.authorKey // Use the provided authorKey
        },
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });

      
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  },

  /**
   * Fetches a user's data from Firestore by their userKey.
   * @param userKey The unique identifier for the user.
   * @returns A Promise that resolves to the User data or null if not found.
   */
  getUserByKey: async (userKey: string): Promise<User | null> => {
    try {
      const userDocRef = doc(db, 'Users', userKey);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        
        return null;
      }

      const userData = userDoc.data();

      return { userKey: userDoc.id, ...userData } as User;
    } catch (error) {
      console.error("Error fetching user by key:", error);
      return null;
    }
  },

  /**
   * Updates the user's profile in Firestore.
   * @param userId The UID of the user, as obtained from Firebase Authentication.
   * @param profileData The profile data to update.
   * @returns A Promise that resolves to void if successful, or rejects with an error.
   */
  updateUserProfile: async (userId: string, profileData: Partial<User['userProfile']>): Promise<void> => {
    try {
      const userDocRef = doc(db, 'Users', userId);
      
      // Ensure userName is not empty if it's being updated
      if ('userName' in profileData && (!profileData.userName || profileData.userName.trim() === '')) {
        throw new Error('Username cannot be empty');
      }

      await updateDoc(userDocRef, {
        'userProfile': profileData,
        updatedAt: Timestamp.now()
      });

      
    } catch (error) {
      console.error("Error updating user profile:", error);
      throw error;
    }
  },

  /**
   * Updates the user's feature flags in Firestore.
   * @param userId The UID of the user.
   * @param featureFlags The feature flags to update.
   * @returns A Promise that resolves to void if successful, or rejects with an error.
   */
  updateUserFeatureFlags: async (userId: string, featureFlags: FeatureFlags): Promise<void> => {
    try {
      const userDocRef = doc(db, 'Users', userId);
      await updateDoc(userDocRef, {
        featureFlags: featureFlags
      });
    } catch (error) {
      console.error("Error updating user feature flags:", error);
      throw error;
    }
  },

  /**
   * Fetches all users from Firestore.
   * @returns A Promise that resolves to an array of User data.
   */
  getAllUsers: async (): Promise<User[]> => {
    try {
      const usersCollectionRef = collection(db, 'Users');
      const usersSnapshot = await getDocs(usersCollectionRef);
      const users: User[] = usersSnapshot.docs.map(doc => ({
        userKey: doc.id,
        ...doc.data()
      } as User));
      return users;
    } catch (error) {
      console.error("Error fetching all users:", error);
      return [];
    }
  },

};

export default UserManager;