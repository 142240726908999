import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button } from "@mui/material";
import TagInputField from './TagInputField';
import "./EditRecording.css";
import { constructUrl } from '../utilities/constructUrl';

interface AuthorInformationFieldsProps {
    initialAuthorName?: string;
    initialAuthorBio?: string;
    initialAuthorWebsite?: string;
    initialAuthorTags?: string[];
    initialAuthorImageUrl?: string;
    authorKey?: string;
    pinKeys?: string[];
    onAuthorDetailsChange: (details: {
        authorName: string;
        authorBio: string;
        authorWebsite: string;
        authorTags: string[];
        imageFile?: File | null;
    }) => void;
    setAuthorTags: React.Dispatch<React.SetStateAction<string[]>>;
    isEditMode?: boolean;
}

const AuthorInformationFields: React.FC<AuthorInformationFieldsProps> = ({
    initialAuthorName = '',
    initialAuthorBio = '',
    initialAuthorWebsite = '',
    initialAuthorTags = [],
    initialAuthorImageUrl = '/assets/icons/user.svg',
    authorKey,
    pinKeys,
    onAuthorDetailsChange,
    setAuthorTags,
    isEditMode = false,
}) => {
    const [authorName, setAuthorName] = useState(initialAuthorName);
    const [authorBio, setAuthorBio] = useState(initialAuthorBio);
    const [authorWebsite, setAuthorWebsite] = useState(initialAuthorWebsite);
    const [authorTags, setLocalAuthorTags] = useState<string[]>(initialAuthorTags);
    const [authorImageUrl, setAuthorImageUrl] = useState(initialAuthorImageUrl);
    const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

    const updateDetails = useCallback((details?: Partial<{
        authorName: string;
        authorBio: string;
        authorWebsite: string;
        authorTags: string[];
        imageFile?: File | null;
    }>) => {
        onAuthorDetailsChange({
            authorName,
            authorBio,
            authorWebsite,
            authorTags,
            imageFile: details?.imageFile || selectedImageFile,
            ...details
        });
    }, [authorName, authorBio, authorWebsite, authorTags, selectedImageFile, onAuthorDetailsChange]);

    const handleAuthorTagsChange = useCallback((newTags: string[] | ((prevTags: string[]) => string[])) => {
        const updatedTags = typeof newTags === 'function' ? newTags(authorTags) : newTags;
        setLocalAuthorTags(updatedTags);
        setAuthorTags(updatedTags);
        updateDetails({ authorTags: updatedTags });
    }, [authorTags, setAuthorTags, updateDetails]);

    useEffect(() => {
        return () => {
            if (authorImageUrl.startsWith('blob:')) {
                URL.revokeObjectURL(authorImageUrl);
            }
        };
    }, [authorImageUrl]);

    const getDisplayImageUrl = useCallback((imageUrl: string) => {
        if (!imageUrl || imageUrl === '/assets/icons/user.svg') {
            return '/assets/icons/user.svg';
        }
        if (imageUrl.startsWith('blob:')) {
            return imageUrl; // Return blob URL directly for newly selected images
        }
        if (isEditMode && imageUrl.startsWith('http')) {
            return imageUrl; // Return full URL for existing images in edit mode
        }
        return isEditMode ? constructUrl(imageUrl) : imageUrl;
    }, [isEditMode]);

    const handleImageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const newImageUrl = URL.createObjectURL(file);
            setAuthorImageUrl(newImageUrl);
            setSelectedImageFile(file);
            updateDetails({ imageFile: file });
        }
    }, [updateDetails]);

    useEffect(() => {
        updateDetails();
    }, [authorName, authorBio, authorWebsite, authorTags.join(','), selectedImageFile, updateDetails]);

    return (
        <div className="author-information-container">
            <a className="author-information2">Author Information</a>
            <img 
                className="artist-image" 
                alt={authorName} 
                src={getDisplayImageUrl(authorImageUrl)}
                onError={(e) => {
                    console.error("Error loading image:", e);
                    console.log("Failed URL:", (e.target as HTMLImageElement).src);
                    (e.target as HTMLImageElement).src = '/assets/icons/user.svg';
                }}
            />
            <Button
                color="primary"
                size="medium"
                variant="contained"
                component="label"
            >
                Select File
                <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                />
            </Button>
            <TextField
                className="input-container"
                label="Name"
                variant="filled"
                value={authorName}
                onChange={(e) => { setAuthorName(e.target.value); updateDetails(); }}
            />
            <TextField
                className="input-container"
                label="Bio"
                variant="filled"
                value={authorBio}
                onChange={(e) => { setAuthorBio(e.target.value); updateDetails(); }}
                multiline
                rows={4}
            />
            <TextField
                className="input-container"
                label="Website"
                variant="filled"
                value={authorWebsite}
                onChange={(e) => { setAuthorWebsite(e.target.value); updateDetails(); }}
            />
            <a className="author-tags">Author Tags</a>
            <TagInputField
                tags={authorTags}
                setTags={handleAuthorTagsChange}
                className="author-tags-input-container"
                chipContainerClassName="tag-chip-container"
            />
        </div>
    );
};

export default AuthorInformationFields;
