import { action, makeAutoObservable, runInAction } from "mobx";
import { IAdminMessage } from "../types/AdminMessage";
import { AdminMessageManager } from "../models/AdminMessageManager";

class AdminMessageStore {
  adminMessages: IAdminMessage[] = [];
  isLoading: boolean = false;
  error: Error | null = null;

  constructor() {
    makeAutoObservable(this, {
      // Explicitly define actions here if needed
      loadAdminMessages: action,
      addMessage: action,
      createAdminMessage: action,
      removeAdminMessage: action,
      fetchAndAddMessage: action,
    });
  }

  loadAdminMessages = async () => {
    this.isLoading = true;
    this.error = null;
    try {
      const messages = await AdminMessageManager.getAllAdminMessages();
      runInAction(() => {
        this.adminMessages = messages;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error as Error;
        this.isLoading = false;
      });
    }
  };

  addMessage = (newMessage: IAdminMessage) => {
    const index = this.adminMessages.findIndex(m => m.pinKey === newMessage.pinKey);
    if (index !== -1) {
      this.adminMessages[index] = newMessage;
    } else {
      this.adminMessages.push(newMessage);
    }
  };

  createAdminMessage = async (newMessage: IAdminMessage) => {
    this.isLoading = true;
    this.error = null;
    try {
      const messageId = await AdminMessageManager.createAdminMessage(newMessage);
      await this.fetchAndAddMessage(messageId);
    } catch (error) {
      runInAction(() => {
        this.error = error as Error;
        this.isLoading = false;
      });
    }
  };

  removeAdminMessage = (key: string) => {
    this.adminMessages = this.adminMessages.filter(message => message.pinKey !== key);
  };

  fetchAndAddMessage = async (pinKey: string) => {
    try {
      const message = await AdminMessageManager.getAdminMessage(pinKey);
      if (message) {
        runInAction(() => {
          this.addMessage(message);
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        console.error('Error fetching and adding message:', error);
        this.error = error as Error;
        this.isLoading = false;
      });
    }
  };
}

const adminMessageStore = new AdminMessageStore();
export default adminMessageStore;