import { db } from '../firebase/firebase'; 
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { IAdminMessage } from '../types/AdminMessage';
import { AuthorManager } from '../models';
import { IAuthor } from '../types';


export const AdminMessageManager = {
    getAllAdminMessages: async (): Promise<IAdminMessage[]> => {
        try {
            const querySnapshot = await getDocs(collection(db, 'Admin Messages'));
            const adminMessages: IAdminMessage[] = [];
            const authorIds = new Set<string>();

            querySnapshot.forEach((doc) => {
                const adminMessage = { key: doc.id, ...doc.data() } as IAdminMessage;
                adminMessages.push(adminMessage);
                if (adminMessage.ownership?.recordingAuthor) {
                    authorIds.add(adminMessage.ownership.recordingAuthor);
                }
            });

            // Fetch authors
            const authorsPromises = Array.from(authorIds).map(authorId =>
              AuthorManager.getAuthor(authorId)
            );
            const fetchedAuthors: (IAuthor | null)[] = await Promise.all(authorsPromises);

            // Associate authors with admin messages
            adminMessages.forEach(message => {
                if (message.ownership?.recordingAuthor) {
                    const author = fetchedAuthors.find(a => a?.authorKey === message.ownership?.recordingAuthor) || null;
                    // Update this line to correctly assign the author object to the message
                    // Ensure the IAdminMessage interface allows an Author object for the author property
                    message.author = author;
                }
            });

            return adminMessages;
        } catch (error) {
            return [];
        }
    },
    
    updateAdminMessage: async (key: string, data: Partial<IAdminMessage>): Promise<void> => {
        try {
          const messageRef = doc(db, 'Admin Messages', key);
          await updateDoc(messageRef, data);
        } catch (error) {
          console.error("Error updating admin message:", error);
          throw error;
        }
      },

    createAdminMessage: async (newMessage: IAdminMessage): Promise<string> => {
    try {
        // Generate a new document ID
        const newDocRef = doc(collection(db, 'Admin Messages'));
        const newDocId = newDocRef.id;

        // Assign the new ID as the pinKey
        const messageWithPinKey = {
            ...newMessage,
            pinKey: newDocId
        };

        // Create the document with the new message data, including the pinKey
        await setDoc(newDocRef, messageWithPinKey);

        return newDocId; // Return the new document's ID
    } catch (error) {
        console.error("Error creating admin message:", error);
        throw error;
    }
    },

    removeAdminMessage: async (key: string): Promise<void> => {
        try {
          const messageRef = doc(db, 'Admin Messages', key);
          await deleteDoc(messageRef);
        } catch (error) {
          console.error("Error removing admin message:", error);
          throw error;
        }
      },

    getAdminMessage: async (pinKey: string): Promise<IAdminMessage | null> => {
        try {
            const docRef = doc(db, 'Admin Messages', pinKey);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                return { pinKey: docSnap.id, ...docSnap.data() } as IAdminMessage;
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error getting admin message:", error);
            throw error;
        }
    },
      
};


//AdminMessages are always expected to be from recordingPath, if ever we change to an audioURL in the future this needs addressing.