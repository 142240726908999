// ProjectContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';

interface ProjectContextProps {
  selectedProject: string;
  setSelectedProject: (project: string) => void;
}

export const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export const ProjectProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  // Initialize state from localStorage
  const [selectedProject, setSelectedProjectState] = useState<string>(
    localStorage.getItem('selectedProject') || ''
  );

  // Update localStorage whenever the selectedProject changes
  useEffect(() => {
    if (selectedProject) {
      localStorage.setItem('selectedProject', selectedProject);
    }
  }, [selectedProject]);

  // Wrap the state setter to include localStorage update
  const setSelectedProject = (project: string) => {
    setSelectedProjectState(project);
    localStorage.setItem('selectedProject', project);
  };

  return (
    <ProjectContext.Provider value={{ selectedProject, setSelectedProject }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within a ProjectProvider');
  }
  return context;
};