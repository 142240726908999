// src/components/AudioPlayer.tsx
import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { audioService } from '../services/AudioService';

interface AudioPlayerProps {
  audioUrl: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    audioService.loadAudio(audioUrl);
    return () => {
      audioService.stop();
    };
  }, [audioUrl]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioService.pause();
    } else {
      audioService.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <IconButton onClick={togglePlayPause}>
      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
  );
};

export default AudioPlayer;