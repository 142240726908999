import { db } from '../firebase/firebase';
import { collection, setDoc, getDoc, doc, updateDoc, deleteDoc, DocumentData, getDocs, query, where } from 'firebase/firestore';
import { IWanderPin } from '../types/WanderPin';


export const WanderManager = {

    fetchAllWanders: async (): Promise<IWanderPin[]> => {
        try {
            const snapshot = await getDocs(collection(db, 'Wanders'));
            const wanders: IWanderPin[] = [];
            snapshot.forEach(doc => {
                wanders.push({ wanderKey: doc.id, ...doc.data() } as IWanderPin);
            });
            return wanders;
        } catch (error) {
            console.error("Error fetching all wanders:", error);
            throw error;
        }
    },

    createWander: async (newWander: IWanderPin): Promise<void> => {
        try {
            await setDoc(doc(db, 'Wanders', newWander.wanderKey), newWander);
        } catch (error) {
            console.error("Error creating new wander:", error);
            throw error;
        }
    },

    getWander: async (wanderKey: string): Promise<IWanderPin | null> => {
        try {
            const wanderDoc = await getDoc(doc(db, 'Wanders', wanderKey));
            if (wanderDoc.exists()) {
                return { wanderKey: wanderDoc.id, ...wanderDoc.data() } as IWanderPin;
            } else {
                return null;
            }
        } catch (error) {
            console.error(`Error fetching wander with wanderKey ${wanderKey}:`, error);
            throw error;
        }
    },

    updateWander: async (updatedWander: IWanderPin): Promise<void> => {
        try {
            const wanderRef = doc(db, 'Wanders', updatedWander.wanderKey);
            await updateDoc(wanderRef, { ...updatedWander });
        } catch (error) {
            console.error("Error updating wander:", error);
            throw error;
        }
    },

    deleteWander: async (wanderKey: string): Promise<void> => {
        try {
            await deleteDoc(doc(db, 'Wanders', wanderKey));
        } catch (error) {
            console.error(`Error deleting wander with wanderKey ${wanderKey}:`, error);
            throw error;
        }
    },


    fetchWanderByOrderNo: async (wanderOrderNo: number): Promise<IWanderPin | null> => {
        try {
            const wandersSnapshot = await getDocs(query(collection(db, 'Wanders'), where('wanderOrderNo', '==', wanderOrderNo)));
            let wanderData: IWanderPin | null = null;
            wandersSnapshot.forEach((doc) => {
                if (!wanderData) { // Assuming only one wander per order number
                    wanderData = { wanderKey: doc.id, ...doc.data() } as IWanderPin;
                }
            });
            if (!wanderData) {
            }
            return wanderData;
        } catch (error) {
            console.error("Error fetching Wander by order number:", error);
            return null;
        }
    },
};